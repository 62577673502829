// components/LineChart.js
import React from "react";
import { useState } from "react";
import { Line } from "react-chartjs-2";
function LineChart(props) {
    const {chartData, title, description, displayDesc, style, chartScales} = props;
    const [scales] = useState(chartScales ? chartScales : {
        y:{
            ticks:{
                beginAtZero: false,
                color: 'white',
                fontSize: 12,
            }
        },
        x: {
            ticks:{
                beginAtZero: false,
                color: 'white',
                fontSize: 12,
            }
        },
    });
    return (
        <div className="chart-container" >
        <h2 style={style}>{title}</h2>
        <Line
            data={chartData}
            options={{
            plugins: {
                title: {
                display: displayDesc,
                text: description,
                },
                legend: {
                    display: false
                }
            },
            scales: scales
            }}
        />
        </div>
    );
}
export default LineChart;
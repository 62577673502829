import {
    CircularProgress,
    IconButton,
    InputAdornment,
    Select,
    TextField,
    InputLabel,
    FormControl,
    Stack,
    Typography,
    MenuItem,
    Autocomplete,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Box } from "@mui/system";
  import "./styles.scss";
  import { Cancel } from "@mui/icons-material";
  import { useRef } from "react";
  import Chip from '@mui/material/Chip';
import { useGetCollectionsQuery } from "api/collectionApi";
import { useGetCategoriesQuery } from "api/libraryApi";
import SearchIcon from '@mui/icons-material/Search';

  const TextInput = (props) => {
    const {
      startIcon,
      endIcon,
      endIconEvent,
      placeholder,
      type,
      input,
      label,
      meta: { touched, error },
    } = props;
    return (
      <Box>
        <div className="input-container">
          {startIcon && (
            <InputAdornment position="start" className="input-icon">{startIcon}</InputAdornment>
          )}
          <TextField
            sx={!startIcon ? { borderRadius: "10px !important" } : null}
            placeholder={placeholder}
            label={label}
            type={type}
            className={startIcon ? "input-content" : "input-content-without-icon"}
            fullWidth
            {...props}
            {...input}
            error={touched && error}
            // helperText={touched && error}
          />
          {endIcon && (
            <InputAdornment
              position="end"
              className="end-icon"
              onClick={endIconEvent}
            >
              <IconButton
                aria-label="toggle visibility"
                onClick={endIconEvent}
                edge="end"
              >
                {endIcon}
              </IconButton>
            </InputAdornment>
          )}
        </div>
        {touched && error && <p className="error-input">{error}</p>}
      </Box>
    );
  };
  
  const SelectInput = (props) => {
    const {
      startIcon,
      labelId,
      id,
      placeholder,
      children,
      input,
      loading,
      label,
      meta: { touched, error },
    } = props;
    const [value, setValue] = useState(null);
    const handleChange = (event) => {
      setValue(event.target.value);
    };
    return (
      <Box>
        <div className="input-container">
          {startIcon ? (
            <InputAdornment className="input-icon">{startIcon}</InputAdornment>
          ) : null}
          <FormControl sx={{ width: "100%" }}  error={touched && error}>
            <InputLabel id={labelId} className="input-label">
              {placeholder}
            </InputLabel>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            {value === null ? (
              <Select
                {...input}
                labelId={labelId}
                label={label}
                onChange={handleChange}
                className={
                  startIcon ? "input-content-left" : "input-content-without-icon"
                }
              >
                {children}
              </Select>
            ) : (
              <Select
                labelId={labelId}
                {...input}
                id={id}
                className={
                  startIcon ? "input-content-left" : "input-content-without-icon"
                }
                displayEmpty
                fullWidth
                value={value}
                label={label}
                onChange={handleChange}
              >
                {children}
              </Select>
            )}
          </FormControl>
        </div>
        {touched && error && <p className="error-input">{error}</p>}
      </Box>
    );
  };

const Tags = ({data, handleDelete}) => {
  return (
    <Box
      sx={{
        background: "#283240",
        // height: "100%",
        display: "flex",
        padding: "0.4rem",
        margin: "0.5rem 0.5rem 0 0",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff"
      }}
    >
      <Stack direction='row' gap={1}>
        <Typography>{data}</Typography>
        <Cancel sx={{ cursor: "pointer" }} onClick={() => {handleDelete(data)}}/>
      </Stack>
    </Box>
  );
};

const TagsInput = (props) => {
  const {placeholder, tags, setTags} = props
  const tagRef = useRef();

  //HandleSubmit
  const submitTag = () => {
    if (tagRef.current.value && tags.indexOf(tagRef.current.value) === -1) {
      setTags([...tags, tagRef.current.value]);
    }
    tagRef.current.value = "";
  }

  const handleDelete = (value) => {
    const newtags = tags.filter((val) => val !== value);
    setTags(newtags);
  };

  return (
    <Box className="tag-input" sx={{ flexGrow: 1 }}>
      <TextField
        onBlur={() => { submitTag() }}
        onKeyDown={(e) => {if (e?.keyCode === 13) { submitTag() }}}
        inputRef={tagRef}
        fullWidth
        margin='none'
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <Box sx={{ margin: "0 0.2rem 0 0", display: "flex", flexWrap: "wrap" }}>
              {tags.map((data, index) => {
                return (
                  <Tags data={data} handleDelete={handleDelete} key={index} />
                );
              })}
            </Box>
          ),
        }}
      />
    </Box>
  );
}

const ChipsInput = (props) => {
  const { categories, curIndex, setCurIndex, ...others } = props

  return (
    <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}} {...others}>
      { categories.map((data, index) => {
        return (data.id) === curIndex
          ?
            <Chip className="active-chip" label={data.title} color='primary' onClick={() => setCurIndex(data.id)} key={data.id}/>
          :
            <Chip className="disabled-chip" label={data.title} variant="outlined" onClick={() => setCurIndex(data.id)} key={data.id}/>
      }) }
    </Stack>
  )
}

const VisibilityInput = (props) => {
  const visibilities = ['public', 'unlisted', 'private']
  const {value, onChange} = props
  return (
    <Box sx={{display: "flex"}}>
      <Typography sx={{
        fontSize: "14px",
        mt:2,
        mr: 2
      }}>Visibility</Typography>
      <Select
          sx={{ '& .MuiSelect-filled': {padding: "10px"}}}
          value={value}
          onChange={onChange}
          displayEmpty
          variant="filled"
          inputProps={{ 'aria-label': 'Without label' }}
      >
          { visibilities.map((data, index)=> {
              return (
                  <MenuItem value={index} key={index}>
                      {data.toUpperCase()}
                  </MenuItem>
              )
          }) }
      </Select>
  </Box>
  )
}

const VisibilityRadioInput = (props) => {
  const visibilities = ['public', 'unlisted', 'private']
  const {value, onChange, label} = props
  const handleChange = (e) => {
    let indexVisibility = visibilities.findIndex((el) => el === e.target.value)
    onChange(indexVisibility)
  }
  return (
    <FormControl>
      <FormLabel id="visibility-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="visibility-group-radio"
        // defaultValue={visibilities[0]}
        name="visibility-group-radio"
        value={visibilities[value]}
        onChange={handleChange}
      >
        {visibilities.map(item => (
          <FormControlLabel sx={{
            '.MuiTypography-root': {
              textTransform: "capitalize"
            }
          }} value={item} control={<Radio />} label={item}/>
        ))}
      </RadioGroup>
    </FormControl>
  )
}

const CollectionInput = (props) => {
  const {label, value, onChange, refetchCollections=true, ...otherProps} = props
  const { data: collections } = useGetCollectionsQuery({}, {
    refetchOnMountOrArgChange: refetchCollections,
  })

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      disablePortal
      id="collection-box"
      options={collections.data}
      getOptionLabel={(opt) => opt[label]}
      sx={{ width: 300 }}
      {...otherProps}
      renderInput={(params) => <TextField {...params} placeholder="Collection" />}
    />
  )
}

const CategoryInput = (props) => {
  const {data: {data: categories}} = useGetCategoriesQuery();
  // const categories = ['Sound Effect', 'Quote', 'Song', 'Other']
  const {curIndex, setCurIndex, ...others} = props
  useEffect(() => {
    if (categories?.length) {
      setCurIndex(categories[0].id)
    }
  }, [])
  return (
    <ChipsInput
      categories={categories}
      curIndex={curIndex}
      setCurIndex={setCurIndex}
      {...others}
    />
  )
}

const SearchBar = ({searchForText, placeholder}) => {
  return (
    <Box className="search-bar" sx={{ mr: 3, ml: 'auto' }}>
      <TextField
        sx={{
          width: 260,
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#fff',
          borderRadius: '20px',
          height: '40px',
          '& input': {
            pl: 2
          },
          '& .MuiInputAdornment-root': {
            pr: 2
          }
        }}
        placeholder={placeholder}
        size="small"
        InputProps={{
          disableUnderline: true,
          endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>
        }}
        variant="standard"
        onKeyDown={e => e.keyCode === 13 && searchForText(e)}
      />
    </Box>
  )
}

export {
  TextInput,
  SelectInput,
  TagsInput,
  ChipsInput,
  VisibilityInput,
  CollectionInput,
  VisibilityRadioInput,
  CategoryInput,
  SearchBar
};
  
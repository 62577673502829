
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, IconButton, Menu, MenuItem, styled } from '@mui/material';
import { useState } from 'react';

const ITEM_HEIGHT = 48;
const OptionsHandler = (props) => {
  const {options, handleSelect} = props
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOption = async (option) => {
    await handleSelect(option)
    setAnchorEl(null)
  }

  return (
    <>
      <Box>
        <Box sx={{ ml: "auto" }}>
          <IconButton
            sx={{ color: "#fff" }}
            onClick={(e) => { setAnchorEl(e.currentTarget); }}
          >
            <MoreHorizIcon sx={{ fontSize: "18px" }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => { setAnchorEl(null) }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 'auto',
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option} onClick={() => { handleOption(option) }}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
    </>
  )
}

export default OptionsHandler
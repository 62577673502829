import { Snackbar, Stack } from "@mui/material";
import React, { useState } from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Alerts = (props) => {
  const { msg, type } = props;
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export { Alerts };

export const WithAlerts = Component => {
  return function WithAlerts(props) {
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState('')
    const [type, setType] = useState('info')
  
    const sendAlert = (msgContent, msgType) => {
      setMsg(msgContent)
      setType(msgType)
      setOpen(true)
    }
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <>
        <Component {...props} sendAlert={sendAlert} />
        <Stack spacing={2} sx={{ width: "100%", display: open ? 'flex' : 'none' }}>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
              {msg}
            </Alert>
          </Snackbar>
        </Stack>
      </>
    );
  }
}



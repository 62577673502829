import { Icon, Stack, styled, Typography } from "@mui/material"
import MusicNoteIcon from '../../assets/svg/music-note.svg'

const NotFoundText = styled(Typography)(() => ({
    color: "#fff",
    fontSize: "18px"
}))

const SvgIcon = styled(Icon)(() => ({
    width: '100%',
    display: 'inline-flex'
}))

const NoResult = (props) => {
    const { icon, notFoundText } = props
    const displayIcon = icon ??
        (<SvgIcon sx={{ fontSize: "200px", justifyContent: "center" }}>
            <img
                src={MusicNoteIcon}
            />
        </SvgIcon>) // default icon
    const displayText = notFoundText ??
        (<NotFoundText>
            No result found
        </NotFoundText>)
    return (
        <Stack alignItems="center" spacing={3} sx={{ mt: 10 }}>
            {displayIcon}
            {displayText}
        </Stack>
    )
}

export { NoResult, NotFoundText }

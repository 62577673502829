import React from 'react';
import {
  BrowserRouter as Router, Routes, Route
} from 'react-router-dom';
import routes from './routes';
import DashboardLayout from '../layouts';
import SignIn from '../pages/login';
import ForgotPassword from '../pages/forgot-pass';
import Register from '../pages/register';
import ResetPassword from 'pages/reset-pass';

const RouteList = () => (
  <Router>
    <Routes>
      <Route path="/sign-in"  element={<SignIn />} key="/sign-in" />
      <Route path="/sign-up"  element={<Register />} key="/sign-up" />
      <Route path="/forgot-password"  element={<ForgotPassword /> } key="/forgot-password"  />
      <Route path="/password/reset"  element={<ResetPassword /> } key="/password/reset"  />
      <Route path="/" element={<DashboardLayout />}>
        {routes.map((route, index) => {
          return (
            <Route
              path={route.path}
              key={index}
              element={route.component}
            ></Route>
          );
        })}
      </Route>

      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  </Router>
);

export default RouteList;

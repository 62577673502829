import { Snackbar, Stack } from "@mui/material";
import React from "react";
import MuiAlert from "@mui/material/Alert";
import useSnackbar from "./useSnackbar";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const GlobalSnackbar = () => {
    const { snackbarState, handleClose } = useSnackbar()

    return (
        <>
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    open={ snackbarState.open }
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Alert onClose={handleClose} severity={ snackbarState.type } sx={{ width: "100%" }}>
                        { snackbarState.msg }
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
}
import { api } from "./api";

export const SoundApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMostPlaySoundList: builder.query({
      query: (params) => ({
        url: "v1/get-most-play-sound-list",
        method: "GET",
        forceRefetch: true,
      }),
    }),
    addUserSoundSetting: builder.mutation({
      query: (body) => ({
          url: "v1/sounds/settings",
          method: "POST",
          body: body
      }),
  }),
  }),
  overrideExisting: true,
});

export const {
    useGetMostPlaySoundListQuery,
    useAddUserSoundSettingMutation
} = SoundApi;

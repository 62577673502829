import { Box, Card, styled, useTheme } from "@mui/material"

const Title = () => null
const Content = () => null

const CardContentStyle = styled(Card)(({theme}) => ({
    ".card-title": {
        fontSize: "22px",
        lineHeight: "33px",
        display: "table-cell",
        verticalAlign: "middle",
        fontWeight: "700",
        color: "#fff",
        padding: "13px 13px 13px 27px",
        margin: "auto auto auto 0px",
    }
}))

const ContentLayout=({children}) => {
    const theme = useTheme()
    const title = children.find(el => el.type === Title)
    const content = children.find(el => el.type === Content)

    return (
        <>
            <CardContentStyle
                variant="outlined"
                sx={{ mx: 3, my: 4, borderRadius: "15px", backgroundColor: theme.palette.blue.main }}
            >
                <Box sx={{ backgroundColor: theme.palette.blue.light, display: "flex" }}>
                    {title ? title.props.children : null}
                </Box>
                <Box>
                    {content ? content.props.children : null}
                </Box>
            </CardContentStyle>
        </>
    )
}

ContentLayout.Title = Title
ContentLayout.Content = Content

export default ContentLayout
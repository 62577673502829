import { Stack, Box, Skeleton, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, InputLabel, Select, MenuItem, Button, OutlinedInput, DialogActions, Backdrop, CircularProgress, Card, CardMedia, CardContent, Typography, useTheme } from "@mui/material"
import { api } from "api/api";
import { useAddCollectionSoundMutation, useGetCollectionsQuery, useGetSoundsFromCollectionQuery } from "api/collectionApi";
import { WithAudio } from "components/customhook/withAudio";
import useSnackbar from "components/snackbar/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SoundCard } from "./LibraryItem";
import musicbg from 'assets/images/music-bg.jpeg'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UseCreateCollectionDialog from "pages/create-collection/CreateCollectionDialog";

export const Sounds = (props) => {
    const { collectionId, searchText } = props;
    let { data: sounds, isFetching } = useGetSoundsFromCollectionQuery(collectionId, { refetchOnMountOrArgChange: true });
    const dispatch = useDispatch()
    const removeSound = (soundId) => {
        dispatch(api.util.updateQueryData('getSoundsFromCollection', collectionId, (sounds) => {
            sounds.data = sounds.data.filter((item) => {
                return item.id !== soundId
            })
        }))
    }

    return (
        <>
            <SoundsGrid
                sounds={sounds}
                isFetching={isFetching}
                searchText={searchText}
                extraInfo={{
                    collectionId
                }}
                onRemoveSound={(soundId) => removeSound(soundId)}
                // removeSoundFromCollection={{
                //     collectionId,
                //     removeSoundFn: (soundId) => { removeSound(soundId) }
                // }}
            />
        </>
    )
}

export const SoundsGrid = WithAudio((props) => {
    const { sounds, isFetching, searchText, extraInfo, onRemoveSound } = props;

    return (
        <UseSaveSoundCollectionDialog>
            {onSaveClick => (
                <>
                    {
                        (isFetching || !sounds)
                            ? ([...Array(6).keys()].map((item, index) => {
                                return (
                                    <Stack spacing={1} key={index}>
                                        <Box className="card-skeleton">
                                            <Skeleton variant="text" width={150} />
                                            <Skeleton variant="circular" width={40} height={40} sx={{ margin: "10px auto 10px 15px" }} />
                                            <Skeleton variant="rectangular" width={150} height={118} />
                                        </Box>
                                    </Stack>
                                )
                            }))
                            : sounds.map(resource => {
                                if (!searchText || resource.title.includes(searchText)) {
                                    return (
                                        <SoundCard
                                            key={resource.id}
                                            resource={resource}
                                            playSound={(src) => props.setAudioSrc(src)}
                                            currentAudio={props.audioSrc}
                                            onRemoveSound={onRemoveSound}
                                            onSaveClick={onSaveClick}
                                            extraInfo={extraInfo}
                                        />
                                    )
                                }
                                return ''
                            })
                    }
                </>
            )}
        </UseSaveSoundCollectionDialog>
    )
})

export const UseSaveSoundCollectionDialog = ({ children }) => {
    const MAX_COLLECTION_PER_LOAD = 6
    const [openDialogCollection, setOpenDialogCollection] = useState(false);
    const [collectionSelected, setCollectionSelected] = useState(-1);

    const {data: collections, isFetching} = useGetCollectionsQuery({});

    const [displayCollections, setDisplayCollections] = useState([])

    useEffect(() => {
        if (collections?.data?.length) {
            setDisplayCollections(collections.data.slice(0, MAX_COLLECTION_PER_LOAD - 1))
        }
    }, [collections])

    const [saveSound, setSaveSound] = useState(0);
    const [addCollectionSound, addCollectionSoundRes] = useAddCollectionSoundMutation();
    const { sendAlert } = useSnackbar()
    const IS_DEFAULT = -1;

    const theme = useTheme()

    const saveData = async (isdefault, collectionSelected, saveSound) => {
        try {
            setOpenDialogCollection(false);
            if (collectionSelected !== 0) {
                const result = await addCollectionSound({ collection: isdefault ? IS_DEFAULT : collectionSelected, sound: saveSound }).unwrap();
                if (result.success) {
                    sendAlert('Add sound success', 'success')
                }
                else {
                    sendAlert(result.error, 'error')
                }
            }
            // setCollectionSelected(0);
            // setSaveSound(0);
        }
        catch (error) {
            sendAlert(error.data.error.message, 'error')
        }
    }

    const onSaveClick = async (soundId, type) => {
        setCollectionSelected(0);
        setSaveSound(soundId);
        if (type === 'collection')
            setOpenDialogCollection(true);
        else {
            // setCollectionSelected(-1);
            await saveData(true, IS_DEFAULT, soundId);
        }
    }

    const onClickCollection = (collectionId) => {
        if (collectionId === collectionSelected) {
            setCollectionSelected(-1)
        } else {
            setCollectionSelected(collectionId)
        }
    }

    const loadMoreCollection = () => {
        if (displayCollections.length < collections.data.length) {
            const currentIndex = displayCollections.length
            setDisplayCollections([...displayCollections,
                ...collections.data.slice(currentIndex, currentIndex + MAX_COLLECTION_PER_LOAD)])
        }
    }

    return (
        <>
            {children(onSaveClick)}
            <Dialog open={openDialogCollection} onClose={() => { setOpenDialogCollection(false); }}
                sx={{
                    '.MuiDialog-paper': {
                        width: 688,
                        padding: '0 18px',
                    }
                }}
            >
                <DialogTitle>
                    <Typography sx={{
                        fontWeight: 700,
                        fontSize: 24,
                        color: '#757575',
                        textAlign: 'center',
                        my: 2
                    }}>
                        Add to collection
                    </Typography>
                </DialogTitle>
                <DialogContent
                    sx={{p: 0}}
                >
                    {isFetching
                    ?   <Box sx={{display: "flex", justifyContent: 'center', p: 2}}>
                            <CircularProgress
                            color="inherit"
                            size={24}/>
                        </Box>
                    :
                    <FormControl sx={{
                        display: 'grid',
                        gap: "11px",
                        placeItems: "center",
                        gridTemplateColumns: "auto auto auto",
                        // gridTemplateColumns: "repeat(auto-fill, minmax(175px, 1fr))",
                        // padding: "20px 20px",
                    }}>
                        {displayCollections.map((collection) => (
                            <Card sx={{boxShadow: 'none', position: 'relative'}} key={collection.id}>
                                <Box
                                    onClick={() => onClickCollection(collection.id)}
                                    sx={{cursor: 'pointer',}}
                                >
                                    <Box sx={{
                                        position: 'absolute',
                                        width: 20,
                                        height: 20,
                                        top: 8,
                                        right: 10,
                                        border: "2px solid white",
                                        borderRadius: '50%',
                                        backgroundColor: collection.id === collectionSelected ? 'white' : 'unset'
                                    }}>
                                    </Box>
                                    <CardMedia
                                        component="img"
                                        height="180"
                                        image={collection.image ?? musicbg}
                                        sx={{
                                            width: 180,
                                            borderRadius: '10px',
                                        }}
                                    >
                                    </CardMedia>
                                </Box>
                                <CardContent sx={{p: 1}}>
                                    <Typography sx={{
                                        fontWeight: 700,
                                        fontSize: 16,
                                        color: '#757575'
                                    }}>
                                        {collection.name}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                        <Card sx={{boxShadow: 'none', alignSelf: 'self-start', cursor: 'pointer'}}>
                        <UseCreateCollectionDialog>
                            {(openDialog) => (
                                <Box sx={{height: 180, width: 180, border: '1px solid #A5A6F6',
                                    borderRadius: '10px', display: 'flex', alignItems: 'center'}}
                                    onClick={() => openDialog()}
                                >
                                    <Typography sx={{
                                        fontWeight: 700,
                                        fontSize: 18,
                                        color: theme.palette.purple.light,
                                        textAlign: 'center'
                                    }}>
                                        Create New Collection&nbsp;
                                        <AddCircleIcon sx={{display: 'table-cell', verticalAlign: 'middle'}}/>
                                    </Typography>
                                </Box>
                            )}
                        </UseCreateCollectionDialog>
                        </Card>
                    </FormControl>}
                    {
                        collections?.data && (displayCollections?.length < collections?.data?.length) &&
                        <Box sx={{display: 'flex', justifyContent: 'center'}}
                            onClick={loadMoreCollection}
                        >
                            <Button variant='outlined' color='purple' sx={{borderRadius: 20}}>
                                <Typography sx={{
                                    fontWeight: 500, fontSize: 12, color: 'purple.light',
                                    textTransform: 'capitalize', padding: '10px 60px'}}>
                                    Load more
                                </Typography>
                            </Button>
                        </Box>
                    }
                </DialogContent>
                <DialogActions
                    sx={{display: 'flex', justifyContent: 'space-between'}}
                >
                    <Button
                        variant='contained' color='grey' sx={{borderRadius: 10, mb:3}}
                        onClick={() => { setOpenDialogCollection(false); }}>
                        <Typography sx={{color: '#fff', textTransform:'capitalize', fontWeight: 500, fontSize: 16, px: 2}}>
                            Back
                        </Typography>
                    </Button>
                    <Button variant='contained' color='purple' sx={{borderRadius: 10, mb:3}}
                        onClick={() => { saveData(false, collectionSelected, saveSound); }}>
                        <Typography sx={{color: '#fff', textTransform:'capitalize', fontWeight: 500, fontSize: 16, px: 2}}>
                            Save
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer - 1,
                    position: "absolute",
                    opacity: 0.5,
                }}
                open={addCollectionSoundRes?.isLoading}
            ><CircularProgress color="inherit" /></Backdrop>
        </>
    )
}
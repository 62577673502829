import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Navigate } from "react-router";
const baseQuery = fetchBaseQuery({
  //baseUrl: window.env.API_URL,
  // baseUrl: 'http://localhost:8000/api', //sandbox
  baseUrl: `${process.env.REACT_APP_BASE_URL}/api`, //live
  // baseUrl: "http://localhost/csp_be/public/crm/v1", //local
  prepareHeaders: (headers, { getState }) => {
    // Get token from store (userSlice)\
    const token = getState().user?.accessToken;
    // Add token to headers
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("Accept", "application/json")
    return headers;
  }
});
const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (args['url'] !== 'login' && result.error && result.error.status === 401) {
    localStorage.removeItem('persist:root');
    localStorage.removeItem('token');
    api.dispatch(api.util.resetApiState())
    return <Navigate to="/sign-in" replace />;
  }
  return result
}

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Collection', 'Sound'],
  endpoints: () => ({}),
});

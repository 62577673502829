import { useDispatch, useSelector } from "react-redux";
import { snackbarSlice } from "store/snackbarSlice";

export default function useSnackbar() {
    const snackbarState = useSelector(state => state.snackbar)
    const dispatch = useDispatch()

    const sendAlert = (msg, type) => {
        dispatch(snackbarSlice.actions.sendAlert({msg, type}))
    }

    const handleClose = () => {
        dispatch(snackbarSlice.actions.close())
    }

    return { snackbarState, handleClose, sendAlert };
}
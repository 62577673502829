import { Box, Skeleton, Stack } from "@mui/material"
import { CollectionCard } from "./LibraryItem";

export const Collections = (props) => {
    const { setActiveCollection, searchText, collections, isFetchingCollections } = props;
    return (
        <>
            {
                isFetchingCollections
                ? ([...Array(6).keys()].map((item, index) => {
                    return (
                        <Stack spacing={1} key={index}>
                            <Box className="card-skeleton">
                                <Skeleton variant="text" width={150}/>
                                <Skeleton variant="circular" width={40} height={40} sx={{margin: "10px auto 10px 15px"}} />
                                <Skeleton variant="rectangular" width={150} height={118} />
                            </Box>
                        </Stack>
                    )
                }))
                : (collections.data.map(resource => {
                    if (!searchText || resource.name.includes(searchText)) {
                        return (
                            <CollectionCard
                                key={resource.id}
                                resource={resource} 
                                // setActiveCollection={() => setActiveCollection(resource.id)}
                            />
                        )
                    }
                    return ''
                }))
            }
        </>
    )
}
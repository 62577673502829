import { Backdrop, Box, CircularProgress, Grid, Typography, styled } from "@mui/material"
import { useEffect, useState } from "react"

import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import LineChart from "components/Chart/LineChart"
import { useGetHistoryQuery } from "api/userApi"
import ContentLayout from "layouts/CardContent";
import SoundWave from "components/sounds/SoundWave";

Chart.register(CategoryScale);

const StreamHistoryStyle = styled(Box)(({theme}) => ({
  '.MuiTypography-root': {
    color: '#fff'
  }
}))

const chartSounPlayScales = {
  y:{
      ticks:{
          beginAtZero: false,
          color: 'white',
          fontSize: 12,
      },
      position: 'left'
  },
  y2:{
    ticks:{
        beginAtZero: false,
        color: 'white',
        fontSize: 12
    },
    position: 'right',
    grid: {
      drawOnChartArea: false,
    },
},
  x: {
      ticks:{
          beginAtZero: false,
          color: 'white',
          fontSize: 12,
      }
  },
};

const StreamHistory = () => {
  const {data, isFetching} = useGetHistoryQuery({}, {refetchOnMountOrArgChange: true});
  const [soundPlaySummaryData, setSoundPlaySummary] = useState(null);
  const [starEarnedSummaryData, setStarEarnedSummary] = useState(null);
  const [paidSoundHistoryData, setPaidSoundHistory] = useState(null);
  const [freeSoundHistoryData, setFreeSoundHistory] = useState(null);
  
  useEffect(() => {
    if (data) {
      setPaidSoundHistory(data.paid_history);
      setFreeSoundHistory(data.free_history);
      setSoundPlaySummary({
        labels: data.free_summary.map((summary) => summary.report_date), 
        datasets: [
          {
            label: "Free Play",
            data: data.free_summary.map((element) => element.total_play),
            borderColor: "yellow",
            borderWidth: 2,
            yAxisID: 'y'
          },
          {
            label: "Paid Play",
            data: data.paid_summary.map((element) => element.total_play),
            borderColor: "red",
            borderWidth: 2,
            yAxisID: 'y2'
          }
        ]
      });
      setStarEarnedSummary({
        labels: data.paid_summary.map((summary) => summary.report_date), 
        datasets: [
          {
            label: "Star Earned",
            data: data.paid_summary.map((element) => element.total_star),
            borderColor: "white",
            borderWidth: 2,
          }
        ]
      });
    }
  }, [isFetching])

  const generateHistory = (historyTitle, historyData) =>
  {
    return (<ContentLayout>
            <ContentLayout.Title>
                  <Typography
                    gutterBottom
                    className="card-title"
                  >
                    {historyTitle}
                  </Typography>
            </ContentLayout.Title>
            <ContentLayout.Content>
              {
                historyData && historyData.map((item) => (
                  <SoundWave
                    key={item.id}
                    soundInfo={item}
                    isHistory={true}
                  />
                ))
              }
            </ContentLayout.Content>
          </ContentLayout>)
  }

  return (
      isFetching ?
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={true}
      >
        <CircularProgress
          color="inherit"
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }} />
      </Backdrop> :
      <StreamHistoryStyle>
      <Grid sx={{ gridTemplateColumns: 'repeat(2, 1fr)', display:'grid' }}>
      <Box sx={{
        alignItems: 'center'
      }}>
        {soundPlaySummaryData && <LineChart chartData={soundPlaySummaryData} 
                                            title="Sound Play Summary" 
                                            displayDesc={false}
                                            chartScales={chartSounPlayScales}
                                            style={{ textAlign: "center", color: "white" }} />}
      </Box>
        <Box sx={{
          alignItems: 'center'
        }}>
          {starEarnedSummaryData && <LineChart chartData={starEarnedSummaryData} title="Star Earned Summary" displayDesc={false} style={{ textAlign: "center", color: "white" }} />}
        </Box>
      </Grid>
      <Grid sx={{ gridTemplateColumns: 'repeat(2, 1fr)', display:'grid', width: '100%' }}>
      {generateHistory("Paid Sound", paidSoundHistoryData)}
      {generateHistory("Free Sound", freeSoundHistoryData)}
      </Grid>
      </StreamHistoryStyle>)
}

export default StreamHistory
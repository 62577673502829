import { api } from "./api";

export const UploadApi = api.injectEndpoints({
    endpoints: (builder) => ({
        uploadSound: builder.mutation({
            query: (body) => ({
                url: "v1/sounds",
                method: "POST",
                body
            })
        }),
        uploadCollection: builder.mutation({
            query: (body) => ({
                url: "v1/collections",
                method: "POST",
                body
            }),
            invalidatesTags: ['Collection'],
        })
    }),
    overrideExisting: true,
})

export const {
    useUploadSoundMutation,
    useUploadCollectionMutation
} = UploadApi;
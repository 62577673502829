import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import { reducer as forms } from "redux-form";
import { api } from "../api/api";
import userReducer from "./userSlice";
import { confirmSlice } from "./confirmSlice";
import { snackbarSlice } from "./snackbarSlice";

const persistConfig = {
  key: "root",
  version: 1,
  blackList: [[api.reducerPath]],
  whiteList: ["user"],
  storage,
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  form: forms,
  user: userReducer,
  confirm: confirmSlice.reducer,
  snackbar: snackbarSlice.reducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

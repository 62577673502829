import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import { Link, useLocation } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { logout } from "../../store/userSlice";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { api } from "../../api/api";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { Icon } from "@mui/material";
import HomeIcon from '../../assets/svg/home.svg'
import MyCollectionIcon from '../../assets/svg/my-collection.svg'
import LogoutIcon from '@mui/icons-material/Logout';
import BlockIcon from '@mui/icons-material/Block';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import logo from 'assets/images/logo.png'

const drawerWidth = 280;

const SvgIcon = styled(Icon)(() => ({
  width: '100%',
  display: 'inline-flex'
}))

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MenuBar({ open, setOpen }) {
  const theme = useTheme()
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const dispatch = useDispatch();
  let [toggles, setToggles] = useState([]);
  const showChildMenu = (pathname) => {
    if (toggles.find((item) => item === pathname)) {
      toggles = toggles.filter((item) => item !== pathname);
      setToggles(toggles);
    } else {
      setToggles([toggles, pathname]);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(api.util.resetApiState());
  };

  const location = useLocation();
  const parentElement = document.querySelector("#menu-list");
  if (parentElement) {
    parentElement.querySelectorAll("a").forEach((element) => {
      if (element.href.replace(/^.*[^]+/, "") === location.pathname) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  }

  return (
    <Box>
      <Drawer
        PaperProps={{
          sx: {
            background: theme.palette.blue.main,
            color: "#fff",
            fontWeight: 600,
            fontSize: "15px",
          },
        }}
        variant="permanent"
        className="nav-sidebar"
        open={open}
      >
        <List className="logo">
          <ListItem button>
            <ListItemIcon>
              <MenuIcon sx={{ color: "#fff" }} onClick={handleDrawerToggle} />
            </ListItemIcon>
            <img
              style={{width: "120px"}}
              src={logo}
              alt="logo"
            />
          </ListItem>
        </List>
        <List id="menu-list">
          <ListItem
            button
            autoFocus
            component={Link}
            to="/"
            className="list-item"
            onClick={() => showChildMenu("/")}
          >
            <ListItemIcon>
              <SvgIcon>
                <img src={HomeIcon} />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText className="list-item--text" primary="Home" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/My-Collections"
          >
            <ListItemIcon>
              <SvgIcon>
                <img src={MyCollectionIcon} />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="My Collections" />
          </ListItem>

          {/* <ListItem
            button
            onClick={() => showChildMenu("/Create/New-Sound")}
          >
            <ListItemIcon>
              <AddToDriveIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Create" />
            {toggles.find(
              (item) => item === "/Create/New-Sound"
            ) ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>
          <Collapse
            in={Boolean(toggles.find(
              (item) => item === "/Create/New-Sound"
            ))}
            timeout="auto"
            unmountOnExit
            className="child-menu"
          >
            <List component="div" disablePadding>
              <ListItem
                button
                component={Link}
                to="/Create/New-Sound"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="New Sound" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/Create/New-Collection"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="New Collection" />
              </ListItem>
            </List>
          </Collapse> */}
          <ListItem
            button
            component={Link}
            to="/Library"
          >
            <ListItemIcon><LibraryMusicIcon sx={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary="Voice Library" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/StreamHistory"
          >
            <ListItemIcon><AssessmentIcon sx={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary="Stream History" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/Blocklist"
          >
            <ListItemIcon><BlockIcon sx={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary="Block List" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/Userinfo"
          >
            <ListItemIcon><PermIdentityIcon sx={{ color: "#fff" }} /></ListItemIcon>
            <ListItemText primary="User Info" />
          </ListItem>
        </List>
        <List sx={{ marginTop: "auto" }}>
          <ListItem button onClick={() => handleLogout()}>
            <ListItemIcon>
              <LogoutIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}

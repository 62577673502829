import { Backdrop, Box, Button, CircularProgress, InputBase, Stack, styled, TextField, Typography } from "@mui/material"
import { api } from "api/api"
import { useDeleteUserMutation, useDisconnectUserMutation, useGetUserQuery, useSaveSettingMutation } from "api/userApi"
import useConfirm from "components/confirm/useConfirm"
import useSnackbar from "components/snackbar/useSnackbar"
import { FBPageConnectButton } from "components/social/dialog/fb-page-connect"
import ContentLayout from "layouts/CardContent"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { logout } from "store/userSlice"

const UserInfoStyle = styled(Box)(({ theme }) => ({
  '.MuiTypography-root': {
    color: '#fff'
  },
  '.detail-info': {
    color: '#fff',
    backgroundColor: theme.palette.blue.light,
    fontSize: '15px',
    height: '50px',
    paddingLeft: '33px',
    paddingRight: '33px',
    borderRadius: '30px',
    width: '45vw',
  }
}))

const TitleTypo = styled(Typography)(({theme}) => ({
  fontSize: "18px",
  fontWeight: 600,
  marginBottom: "5px"
}))

const UserInfo = () => {
  const { data: user, isFetching } = useGetUserQuery('self', { refetchOnMountOrArgChange: true });
  const [deleteUser, deleteUserRes] = useDeleteUserMutation();
  const [disconnectUser, disconnectUserRes] = useDisconnectUserMutation();
  const [saveSettingApi, saveSettingRes] = useSaveSettingMutation();
  const { confirm } = useConfirm()
  const {sendAlert} = useSnackbar()
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(api.util.resetApiState());
  };

  const deleteAccount = async () => {
    try {
      let res = await confirm({
        text: "Are you sure you want to delete your account?",
        title: "Confirm",
        callback: async() => {
          await deleteUser('self').unwrap()
        }
      })
      // handle success
      if (res) {
        sendAlert("Succesfully delete user account", "success")
        handleLogout()
      }
    } catch (e) {
      console.log(e)
      sendAlert("An Unexpected Error Occurred", "error")
    }
  }

  const [limitPlay, setLimitPlay] = useState(user?.value?.free_play_limit ? user?.value?.free_play_limit : '')

  useEffect(() => {
    setLimitPlay(user?.value?.free_play_limit ? user?.value?.free_play_limit : '');
  }, [user])

  const onChangeLimit = (e) => {
    let amount = 0
    if (e.target.value && /^[0-9]*$/.test(e.target.value)){
      amount = e.target.value
    }
    // setIsSettingChanged(true)
    setLimitPlay(Number(amount))
  }

  const disconnectAccount = async () => {
    try {
      let res = await confirm({
        text: "Are you sure you want to disconnect your facebook account?",
        title: "Confirm",
        callback: async() => {
          await disconnectUser().unwrap()
          dispatch(api.util.updateQueryData('getUser', 'self', (res) => {
            res.value.obs_id = null
          }))
        }
      })
      // handle success
      if (res) {
        sendAlert("Succesfully disconnect user account", "success")
      }
    } catch (e) {
      console.log(e)
    }
  }

  const saveSetting = async () => {
    try {
      let res = await confirm({
        text: "Are you sure you want to save the free play limit?",
        title: "Confirm",
        callback: async() => {
          await saveSettingApi({limit_free_play: limitPlay}).unwrap();
        }
      })
      // handle success
      if (res) {
        sendAlert("Save Succesfully", "success")
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    isFetching ?
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={true}
      >
        <CircularProgress
          color="inherit"
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }} />
      </Backdrop> :
      <UserInfoStyle>
        <ContentLayout>
          <ContentLayout.Title>
            <Typography
              className="card-title"
            >
              User Info
            </Typography>
          </ContentLayout.Title>
          <ContentLayout.Content>
            <Stack spacing={2} sx={{
              p: "10px 0px 20px 20px"
            }}>
              <Box>
                <TitleTypo>
                  Username
                </TitleTypo>
                <InputBase
                  className="detail-info"
                  inputProps={{ "aria-label": "User name" }}
                  value={user.value.name}
                  readOnly
                />
              </Box>
              <Box>
                <TitleTypo>
                  Email
                </TitleTypo>
                <InputBase
                  className="detail-info"
                  inputProps={{ "aria-label": "User email" }}
                  value={user.value.email}
                  readOnly
                />
              </Box>
              <Box>
                <TitleTypo>
                  Facebook page
                </TitleTypo>
                <InputBase
                  className="detail-info"
                  inputProps={{ "aria-label": "Facebook page" }}
                  value={user?.value?.obs_id ? user?.value?.obs_id : ''}
                  readOnly
                />
                {
                  user?.value?.obs_id ?
                    (
                    <Button sx={{
                      marginLeft: "10px",
                      '&.MuiButton-root': {
                        backgroundColor: 'orange.light'
                      }
                    }}
                    variant="contained"
                    onClick={() => disconnectAccount()}
                  >
                    <Typography sx={{fontSize: '14px'}}>
                      Disconnect
                    </Typography>
                  </Button>
                    ) : 
                    (<FBPageConnectButton
                      sx={{
                        minWidth: "200px",
                        backgroundColor: 'purple.main',
                        height: "50px",
                        padding: "0px 10px 0px 10px",
                        margin: "0px 0px 0px 10px",
                        borderRadius: "4px",
                        marginLeft: "10px",
                        '&.MuiButton-contained:hover': {
                          backgroundColor: 'purple.dark',
                        },
                        ".MuiTypography-root": {
                          fontSize: "14px"
                        }
                      }}
                    />)
                }
                
              </Box>
              <Box>
                <TitleTypo>
                  Free Play Limit
                </TitleTypo>
                <InputBase
                  className="detail-info"
                  inputProps={{ "aria-label": "User name", inputMode: 'numeric', pattern: '^[0-9]*' }}
                  value={limitPlay}
                  onChange={onChangeLimit} />
                <Button sx={{
                    marginLeft: "10px",
                    '&.MuiButton-root': {
                      backgroundColor: 'orange.light'
                    }
                  }}
                  variant="contained"
                  onClick={() => saveSetting()}
                >
                  <Typography sx={{fontSize: '14px'}}>
                    Save Setting
                  </Typography>
                </Button>
              </Box>
              <Box>
                <Button sx={{
                  '&.MuiButton-root': {
                    backgroundColor: 'orange.light'
                  }
                }}
                  variant="contained"
                  onClick={() => deleteAccount()}
                >
                  <Typography sx={{fontSize: '14px'}}>
                    Delete account
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </ContentLayout.Content>
        </ContentLayout>
      </UserInfoStyle>
  )
}

export default UserInfo
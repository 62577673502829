import { Box, Button, Typography, useTheme } from '@mui/material'
import { useState, useEffect } from 'react'

const useLoadMore = (props) => {
    const { numPerLoad, srcData } = props
    const [loadData, setLoadData] = useState([])
    const theme = useTheme()
    useEffect(() => {
        if (srcData && srcData.length && loadData.length === 0) {
            setLoadData(srcData.slice(0, numPerLoad))
        }
    }, [srcData])

    const onLoadMore = () => {
        if (loadData?.length < srcData.length) {
            setLoadData([...loadData, ...srcData.slice(
                loadData.length,
                loadData.length + numPerLoad)
            ])
        }
    }

    const renderButtonLoadMore = (
        (srcData && loadData.length < srcData.length) && <Box sx={{ display: 'flex', justifyContent: 'center' }}
            onClick={onLoadMore}
        >
            <Button variant='outlined' color='purple' sx={{
                borderRadius: 20,
                border: `2px solid ${theme.palette.purple.light}`,
                mb: 2,
                "&:hover": {
                    borderWidth: "2px"
                }
            }}>
                <Typography sx={{
                    fontWeight: 500, fontSize: 18, color: 'purple.light',
                    textTransform: 'capitalize', padding: '4px 20px'
                }}>
                    Load more
                </Typography>
            </Button>
        </Box>
    )

    return { loadData, setLoadData, renderButtonLoadMore }
}

export default useLoadMore
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useLoginAndGetDataMutation } from "../../api/userApi";
import { TextInput } from "../../components/input/index";
import { Field, reduxForm } from "redux-form";
import Validation from "../../helpers/validation";
import "./styles.scss";
import {Alerts} from "../../components/alert";
import { Backdrop, CircularProgress, CardActions } from "@mui/material";
import Layout from "../../layouts/BasicLayout";
import { FacebookButton } from '../../components/social/facebook'
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.png'
import useSnackbar from "components/snackbar/useSnackbar";

const theme = createTheme();
let SignIn = (props) => {
  const {sendAlert} = useSnackbar()
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { handleSubmit, submitting } = props;
  const [loginAndGetData, { isLoading, error }] = useLoginAndGetDataMutation();
  const submit = async (form) => {
    try {
      await loginAndGetData({
        email: form.email,
        password: form.password,
      }).unwrap();
    } catch (err) {
      sendAlert(err, "error")
    }
  };

  const [message, setMessage] = useState('');

  React.useEffect(()=>
  {
    if(error)
    {
      setMessage('');
        setTimeout(function() {
          setMessage(error.message);
      }, 100);
    }
  }, [error]);

  // handle facebook login failure
  const onFacebookLoginFail = (res) => {
    setMessage('')
    setTimeout(() => {
      setMessage('Unauthorized!')
    }, 100)
  }

  return (
    <>
      <div className="sign-in-contents">
        <Layout>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm" className="login-box"
              sx={{
                display: "flex", alignItems:"center", flexDirection:"column"
              }}
            >
              <CssBaseline />
              <Box>
                <img
                  style={{width: "100%"}}
                  src={logo}
                  alt='logo'
                />
              </Box>
              <Box className="sign-in-container">
                <Box>
                  <CardActions>
                    <FacebookButton
                      onFailure={onFacebookLoginFail}
                      textButton="Login With Facebook"
                      sx={{
                        minWidth: "280px", height: "50px",
                        backgroundColor:"#1F6EEE",
                        ".MuiTypography-root": {
                          fontWeight: 700, fontSize: "14px", fontFamily: "Poppins"
                        }
                      }}
                    />
                  </CardActions>
              </Box>
              <div className="seperation">
                <Typography component="p">
                  or
                </Typography>
              </div>
                <Box
                  component="form"
                  sx={{ mt: 1 }}
                  onSubmit={handleSubmit((form) => submit(form))}
                >
                  <Field
                    placeholder="Email address"
                    name="email"
                    component={TextInput}
                    // startIcon={<MailOutlinedIcon />}
                    validate={[Validation.required, Validation.email]}
                  ></Field>
                  <Field
                    // startIcon={<LockOutlinedIcon />}
                    endIconEvent={() => handleClickShowPassword()}
                    endIcon={
                      showPassword ? (
                        <Visibility sx={{ color: "#1267FC" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "#1267FC" }} />
                      )
                    }
                    placeholder="Password"
                    fullWidth
                    name="password"
                    type={showPassword ? "text" : "password"}
                    component={TextInput}
                    validate={[Validation.required]}
                  ></Field>
                  {/* <Field component={DatePickerInput} name="datettimee" validate={[Validation.required]} ></Field> */}
                  <Button
                    type="submit"
                    className="submit"
                    fullWidth
                    variant="contained"
                    disabled={submitting}
                  >
                    <span>Sign In</span>
                  </Button>
                  
                  {/* {error && (
                    <p style={{ color: 'red' }}>
                      {error.status === 400
                        ? error.data?.message
                        : 'Cannot connect to server!'}
                    </p>
                  )} */}
                  <Box className="remind">
                    <Box>
                      <Link to="/forgot-password">
                        Forgot your password?
                      </Link>
                      </Box>
                      <Box>
                      <span>&nbsp;Don't have an account? &nbsp;</span>
                      <Link to="/sign-up">
                        Sign up
                      </Link>
                      </Box>
                  </Box>
                </Box>
              </Box>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer - 1,
                  position: "absolute",
                  opacity: 0.5,
                }}
                open={isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Container>
          </ThemeProvider>
        </Layout>
      </div>
      {message && <Alerts msg={message} type="error" />}
    </>
  );
};

SignIn = reduxForm({
  form: "signInForm",
})(SignIn);

export default SignIn;

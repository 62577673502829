import * as React from 'react';
import './styles.scss';
import { useGetCategorySoundsQuery } from '../../../api/libraryApi';
import { TablePagination } from '@mui/material';
import { useParams } from "react-router-dom";
import { useState } from 'react';
import { Box } from '@mui/system';
import SoundGridLayout from 'layouts/soundsGrid';
import { SoundsGrid } from 'pages/my-collection/Sounds';
const CategorySounds = () => {

  const { id } = useParams();
  const categorySounds = useGetCategorySoundsQuery({id}, { refetchOnMountOrArgChange: true, cacheTime:0 });

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(event.target.value);
     //const moreFilter = { page: 1, page_size: event.target.value };
     //filterChange(moreFilter);
   };
   const [page, setPage] = useState(0);
   const handleChangePage = (event, newPage) => {
    setPage(newPage)
    //const moreFilter = { page: newPage + 1 };
    //filterChange(moreFilter);
  };

  return (
    <div>
      {/* {alert} */}
    <Box id='category-sound'>
      <SoundGridLayout>
        <SoundsGrid
            sounds={categorySounds?.data}
            isFetching={categorySounds?.isFetching}
        />
      </SoundGridLayout>
    </Box>
    <TablePagination
        component="div"
        count={categorySounds?.meta?.total ? categorySounds?.meta?.total : 1}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        showFirstButton
        showLastButton
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".MuiTablePagination-displayedRows": {
            color: "white",
          },
          ".MuiTablePagination-selectLabel": {
            color: "white",
          },
          ".MuiTablePagination-select": {
            color: "white",
          },
          ".MuiButtonBase-root": {
            color: "white",
          },
          ".MuiSelect-icon": {
            color: "white",
          },
          ".MuiIconButton-root": {
            color: "white",
          },
          ".MuiSvgIcon-root": {
            color: "white",
          },
          ".MuiSvgIcon-fontSizeMedium": {
            color: "white",
          },
        }}
    />
    </div>
  );
}

export default CategorySounds;
import { api } from "./api";

export const LibraryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (params) => ({
        url: "v1/getCategories",
        method: "GET",
        params: params,
        forceRefetch: true,
      }),
    }),
    getCategorySounds: builder.query({
      query: (params) => ({
        url: `v1/getCategory-sounds/${params.id === -1 ? '' : params.id}`,
        method: "GET",
        forceRefetch: true,
      }),
    }),
    getLibrarySounds: builder.query({
      query: (params) => ({
        url: "v1/get-library-sound-list/",
        method: "GET",
        params: params,
        forceRefetch: true,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCategoriesQuery,
  useGetCategorySoundsQuery,
  useGetLibrarySoundsQuery
} = LibraryApi;

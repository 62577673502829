import { api } from "./api";

export const UserApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
    }),
    register: builder.mutation({
      query: (userInfo) => ({
        url: "register",
        method: "POST",
        body: userInfo,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (info) => ({
        url: "password/forgot",
        method: "POST",
        body: info
      })
    }),
    resetPassword: builder.mutation({
      query: (info) => ({
        url: "password/reset",
        method: "POST",
        body: info
      })
    }),
    getUserInfo: builder.query({
      query: () => "me",
      subscribe: false,
      forceRefetch: true,
    }),
    loginAndGetData: builder.mutation({
      async queryFn(credentials, { dispatch, getState }, option, fetchWithBQ) {
          // Login with credentials
          const loginRes = await dispatch(
            UserApi.endpoints.login.initiate(credentials)
          );
          // Throw error if login was not success
          if (loginRes.error) return { error: loginRes.error?.data?.message };
          // Get user info and store it in state
          //const userInfo = await dispatch(
          //  UserApi.endpoints.getUserInfo.initiate()
          //);
          // Return data or error when getting userInfo
          return { data: loginRes.data };
      },
    }),
    loginSocial: builder.mutation({
      query: ({platform, access_token}) => ({
        url: `loginsocial/${platform}`,
        method: "POST",
        body: {access_token}
      })
    }),
    getObsId: builder.query({
      query: () => 'v1/get-obsId',
      subscribe: false,
      forceRefetch: true,
    }),
    setObsId: builder.mutation({
      query: ({fb_page_id}) => ({
        url: 'v1/set-obsId',
        method: "POST",
        body: {fb_page_id}
      })
    }),
    getFbId: builder.query({
      query: ({platform}) => `v1/get-social-id/${platform}`,
      subscribe: false,
      forceRefetch: true,
    }),
    connectSocial: builder.mutation({
      query: ({platform, access_token}) => ({
        url: `v1/connect-social/${platform}`,
        method: "POST",
        body: {access_token}
      })
    }),
    generateFanCode: builder.mutation({
      query: () => ({
        url: `v1/generate-fan-code`,
        method: "POST"
      })
    }),
    getViewerList: builder.query({
      query: () => 'v1/viewers',
      subscribe: false,
      forceRefetch: true,
    }),
    blockViewer: builder.mutation({
      query: (body) => ({
        url: `v1/viewers/block`,
        method: 'POST',
        headers: {
          accept: 'application/json'
        },
        body
      })
    }),
    getUser: builder.query({
      query: (userId) => `v1/users/${userId}`,
      subscribe: false,
      forceRefetch: true,
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `v1/users/${userId}`,
        method: "DELETE"
      })
    }),
    disconnectUser: builder.mutation({
      query: () => ({
        url: `v1/users/disconnect`,
        method: "POST"
      })
    }),
    saveSetting: builder.mutation({
      query: (body) => ({
        url: `v1/users/save-setting`,
        method: "POST",
        headers: {
          accept: 'application/json'
        },
        body
      })
    }),
    getHistory: builder.query({
      query: () => 'v1/getHistory',
      subscribe: false,
      forceRefetch: true,
    }),
  }),
  overrideExisting: true,
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetUserInfoQuery,
  useLoginAndGetDataMutation,
  useLoginSocialMutation,
  useSetObsIdMutation,
  useGetObsIdQuery,
  useGetFbIdQuery,
  useConnectSocialMutation,
  useGenerateFanCodeMutation,
  useGetViewerListQuery,
  useBlockViewerMutation,
  useDeleteUserMutation,
  useDisconnectUserMutation,
  useGetUserQuery,
  useSaveSettingMutation,
  useGetHistoryQuery
} = UserApi;

import { Box } from "@mui/system";

function SoundGridLayout({children}) {
  return (
    <Box
        sx={{
            display: "grid",
            gap: "35px",
            // gridAutoFlow: "240px",
            gridTemplateColumns: "repeat(auto-fill, minmax(163px, 1fr))",
            placeItems: "center",
            padding: "30px 27px",
        }}
    >
        {children}
    </Box>
  );
}

export default SoundGridLayout;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import RouteList from './routes';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import ConfirmDialog from 'components/confirm';
import { GlobalSnackbar } from 'components/snackbar';

const theme = createTheme({
  typography: {
    fontFamily: [
      "Poppins",
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    // allVariants: {
    //   fontFamily: 'Jura',
    // },
  },
  palette: {
    blue: {
      dark: '#162240',
      main: '#2C4582',
      light: '#41578E'
    },
    purple: {
      dark: "#4b4dbb",
      main: "#5D5FEF",
      light: "#A5A6F6"
    },
    orange: {
      light: "#FF5934",
    },
    grey: {
      main: '#838383'
    }
  },
})

let persistor = persistStore(store)
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouteList />
          <ConfirmDialog />
          <GlobalSnackbar />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();

import { Box, Button, Icon, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, Typography } from "@mui/material"
import { useUploadSoundMutation } from "api/uploadApi"
import React, { useState } from "react"
import SubmitSound from "./SubmitSound"
import SubmitSoundDetails from "./SubmitSoundDetails"
import FinishUpload from "./FinishUpload";
import SubmitSoundFinish from "./SubmitSoundFinish"

const { default: DialogLayout } = require("layouts/Dialog")

const SubmitSoundContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "36px 45px",
  width: "619px",
  '.MuiStepLabel-label': {
    '.MuiTypography-root': {
      fontWeight: 700,
      fontSize: 16,
      color: theme.palette.orange.light
    },
    '&.Mui-disabled': {
      opacity: 0.5
    }
  },
}))


const CustomStepIconStyle = styled('div')(({ theme, ownerState }) => ({
  zIndex: 1,
  color: '#fff',
  width: 36,
  height: 36,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...((ownerState.active || ownerState.completed) && {
    backgroundColor: theme.palette.orange.light,
  }),
  ...((!ownerState.active && !ownerState.completed) && {
    backgroundColor: theme.palette.orange.light,
    opacity: 0.5
  }),
}))

const CustomStepIcon = (props) => {
  const { active, completed, className } = props;
  return (
    <CustomStepIconStyle ownerState={{ completed, active }} className={className}>
      <Typography sx={{
        fontWeight: 700,
        fontSize: "16px"
      }}>
        {props.icon}
      </Typography>
    </CustomStepIconStyle>
  )
}

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 14,
    left: "calc(-50% + 28px)",
    right: "calc(50% + 28px)"
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.orange.light,
    opacity: 0.5,
    borderWidth: 6,
    borderRadius: 1,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.orange.light,
      opacity: "unset"
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.orange.light,
      opacity: "unset"
    },
  },
}))

const UseUploadSoundDialog = ({ children }) => {
  const [uploadSound, uploadState] = useUploadSoundMutation();
  const [activeStep, setActiveStep] = useState(0);

  const defaultBasicInfo = {
    audio: null,
    audioSrc: '',
    cover: null,
    coverSrc: null,
    soundTitle: '',
    visibility: 0,
    category: null,
    tags: [],
    description: '',
    soundLength: 0,
    collection: null,
  }

  const [basicInfo, setBasicInfo] = useState(defaultBasicInfo)

  const initInfo = () => {
    setActiveStep(0)
    setBasicInfo(defaultBasicInfo)
  }

  const handleSwitchStep = (step) => {
    return () => { setActiveStep((prevActiveStep) => prevActiveStep + step) }
  }

  const handleUpload = async () => {
    const soundPayload = new FormData()
    soundPayload.append('sound', basicInfo.audio)
    soundPayload.append('sound_title', basicInfo.soundTitle)
    soundPayload.append('sound_length', basicInfo.soundLength)
    soundPayload.append('visibility', basicInfo.visibility)
    soundPayload.append('category', basicInfo.category)
    soundPayload.append('tags', basicInfo.tags.join(', ') || 'default')
    soundPayload.append('description', basicInfo.description)
    soundPayload.append('collection', basicInfo.collection ? basicInfo.collection.id : -1)
    if (basicInfo.cover) {
        soundPayload.append('cover', basicInfo.cover)
    }
    return await uploadSound(soundPayload).unwrap()
  }

  const steps = [
    {
      name: 'Create Sound',
      component: <SubmitSound 
        setBasicInfo={setBasicInfo}
        basicInfo={basicInfo}
        handleSwitchStep={handleSwitchStep}
      />
    },
    {
      name: 'Detail Information',
      component: <SubmitSoundDetails 
        setBasicInfo={setBasicInfo}
        basicInfo={basicInfo}
        handleSwitchStep={handleSwitchStep}
        handleUpload={handleUpload}
        uploadState={uploadState}
      />
    },
    {
      name: 'Finish',
      component: <SubmitSoundFinish />
    }
  ]
  return (
    <DialogLayout>
      <DialogLayout.Context>
        {({ setOpen }) => children(() => {
          initInfo()
          setOpen(true)
        })}
      </DialogLayout.Context>
      <DialogLayout.Content>
        {({ setOpen }) => <>
          <SubmitSoundContainer>
            <Typography sx={{
              fontSize: "32px", mb: 3, color: "#757575",
              mx: "auto", fontWeight: 700
            }}>
              Create Sound
            </Typography>
            <Stepper activeStep={activeStep} connector={<CustomStepConnector />}
              alternativeLabel sx={{
                mx: -10, mb:5
              }}>
              {steps.map((step, index) => {
                return (
                  <Step key={step.name}>
                    <StepLabel StepIconComponent={CustomStepIcon}>
                      <Typography>{step.name}</Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {
              React.cloneElement(steps[activeStep].component, {
                setOpen: setOpen
              })
            }
          </SubmitSoundContainer>
        </>}
      </DialogLayout.Content>
    </DialogLayout>
  )
}

export default UseUploadSoundDialog
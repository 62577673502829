import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import "./styles.scss";
import ContentLayout from "layouts/CardContent";
import { useGetCategoriesQuery, useGetCategorySoundsQuery } from "api/libraryApi";
import SoundWave from "components/sounds/SoundWave";
import UseUploadSoundDialog from "pages/create-sound/SubmitSoundDialog";
import { ChipsInput, SearchBar } from "components/input";
import useCustomPagination from "components/customhook/usePagination";
import { ITEM_PER_PAGE } from "helpers/constants";
import { NoResult, NotFoundText } from "components/common/NoResult";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const Library = () => {
  const [isloading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [currentTab, setCurrentTab] = useState(0);
  const {data: collections, isFetching, refetch} = useGetCategorySoundsQuery(
    {id: selectedCategory}, {refetchOnMountOrArgChange: true});
  const {data: categories} = useGetCategoriesQuery();
  const [loadData, setLoadData] = useState([]);
  const [notFound, setNotFound] = useState('');

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const [searchText, setSearchText] = useState('');

  const searchForText = (e) => {
    setSearchText(e.target.value)
  }

  useEffect(() => {
    if (collections) {
      setLoadData(collections.data.filter(
        (collection) => !searchText || collection.title.includes(searchText)
      ))
    }
  }, [searchText, collections])

  useEffect(() => {
    if (!loadData.length) {
      if (searchText) {
        setNotFound('search_text')
      } else {
        setNotFound('result')
      }
    } else {
      setNotFound('')
    }
  }, [loadData, searchText])

  const {renderPagination, page} = useCustomPagination({loadData})

  return (
    <Box id="my-library"  sx={{width:"100%"}}>
      <ContentLayout>
          <ContentLayout.Title>
            <Typography
              gutterBottom
              className="card-title"
              sx={{ mb: 0 }}
            >
              Library
            </Typography>
            <UseUploadSoundDialog>
              {(openDialog) => (
                <Button
                  variant="contained"
                  onClick={() => openDialog()}
                  sx={{
                    '&.MuiButton-root': {
                      backgroundColor: 'purple.main',
                      height: 43,
                      padding: '0px 9px',
                      borderRadius: '15px',
                      margin: '13px 30px 13px auto',
                    }
                  }}
                >
                  Create new Sound
                </Button>
              )}
            </UseUploadSoundDialog>
          </ContentLayout.Title>
          <ContentLayout.Content>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={currentTab} onChange={handleChangeTab}
                aria-label="sounds tab" className="sounds-tab-panel"
                variant="fullWidth"
                textColor="#fff"
              >
                <Tab className="sounds-tab" label="Alerts" />
                {/* <Tab className="sounds-tab" label="Songs" />
                <Tab className="sounds-tab" label="Quote" />
                <Tab className="sounds-tab" label="Other" /> */}
              </Tabs>
            </Box>
            <Box sx={{ display: 'flex', mt: 3, alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 500, fontSize: 16, color: '#fff', mx: 3 }}>
                Filter:
              </Typography>
              <ChipsInput
                categories={[{ id: -1, title: 'Show all' }, ...categories?.data ?? []]}
                curIndex={selectedCategory}
                setCurIndex={val => setSelectedCategory(val)}
                sx={{
                  'flexWrap': 'wrap',
                  '& .active-chip': {
                    color: '#fff',
                    backgroundColor: 'purple.main',
                    '&:hover': {
                      backgroundColor: 'purple.main',
                    }
                  },
                  '& .disabled-chip': {
                    color: '#fff',
                    backgroundColor: '#ffffff1a',
                    '&:hover': {
                      backgroundColor: '#ffffff1a',
                    }
                  },
                  '.MuiChip-label': {
                    fontSize: 16,
                    fontWeight: 500
                  },
                  '.MuiChip-root': {
                    mb: 0.75,
                    mr: 0.75,
                    ml: 0,
                  },
                  mb: 1
                }}
              />
              <SearchBar
                searchForText={searchForText}
                placeholder="Search Sound"
              />
            </Box>
            {(isloading || isFetching) ? 
            <CircularProgress
            size={36}
            sx={{
                color: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }} /> :
            <Box>
              <TabPanel value={currentTab} index={0}>
                {
                  notFound &&
                  <NoResult
                    notFoundText={
                      (notFound === 'search_text')
                        ? (<>
                          <NotFoundText sx={{ fontWeight: 700 }}>
                            No result for "{searchText}"
                          </NotFoundText>
                          <NotFoundText>
                            Try search with another keyword
                          </NotFoundText>
                        </>)
                        : null
                    }
                  />
                }
                {
                  loadData.slice((page-1) * ITEM_PER_PAGE, page * ITEM_PER_PAGE).map((item) => (
                    <SoundWave
                      key={item.id}
                      soundInfo={item}
                    />
                  ))
                }
              </TabPanel>
              <TabPanel value={currentTab} index={1}>
                <NoResult />
              </TabPanel>
              <TabPanel value={currentTab} index={2}>
                <NoResult />
              </TabPanel>
              <TabPanel value={currentTab} index={3}>
                <NoResult />
              </TabPanel>
              <Box sx={{display: 'flex'}}>
                {renderPagination}
              </Box>
            </Box>
            }
          </ContentLayout.Content>
        </ContentLayout>
    </Box>
  );
};

export default Library;

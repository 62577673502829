import {
  Card,
  Box,
  Typography,
  Icon,
  styled,
  Tooltip
} from "@mui/material";
import musicbg from 'assets/images/music-bg.jpeg'
import PlayIcon from '../../assets/svg/play-button.svg'
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { useNavigate } from "react-router-dom";
import OptionsHandler from "components/sounds/OptionsHandler";
import useDeleteFromCollection from "components/customhook/useDeleteFromCollection";

const SvgIcon = styled(Icon)(() => ({
  width: '100%',
  display: 'inline-flex'
}))

const simpleHash = (str) => Array.from(str).reduce((sum, chr) => sum + chr.charCodeAt(), 0)

export const SoundCard = (props) => {
  const colors=["#c1961533", "#138dd747", "#4713d747", "#d7136f47"]
  const {
    resource,
    playSound,
    currentAudio,
    extraInfo:{collectionId}={},
    onSaveClick,
    onRemoveSound
  } = props;
  const colorIndex = simpleHash(resource.title) % colors.length
  // handle menu
  let options=[
    'Add to default collection',
    'Add to collection'
  ]
  if (collectionId) {
    options.push('Delete from collection')
  }
  const deleteFromCollection = useDeleteFromCollection({
    soundId: resource.id,
    collectionId: collectionId,
    callback: () => onRemoveSound(resource.id)
  })

  // handle delete sound
  const handleSelect = async (option) => {
    switch (option) {
      case 'Delete from collection':
        await deleteFromCollection()
        break;
      case 'Add to default collection':
        onSaveClick(resource.id, 'add')
        break;
      case 'Add to collection':
        onSaveClick(resource.id, 'collection')
        break;
      default:
        break;
    }
  }

 /**
  * If the new audio is the same as the current audio, then play the current audio. Otherwise, play the
  * new audio.
  * @param newAudio - The audio file that is being clicked on.
  * @returns the playSound function with the newAudio parameter.
  */
  const handleClickSound = (newAudio) => {
    if (currentAudio && newAudio === currentAudio) {
      return playSound('')
    }
    playSound(newAudio)
  }

  return (
    <Card className="sound-card">
      <Box className="sound-card-bg"
        sx={{
            backgroundImage: `url("${resource.image_link ?? musicbg}")`,
            boxShadow: resource.image_link
              ? 'unset'
              : `inset 0 0 0 100vmax ${colors[colorIndex]}`
        }}
        onClick={() => {handleClickSound(resource.link)}}
      >
        {
          currentAudio && currentAudio === resource.link
            ? <PauseCircleIcon />
            : <SvgIcon><img src={PlayIcon} /></SvgIcon>
        }
      </Box>
      <Box className="sound-card-info">
        <Tooltip title={resource.title}>
          <Typography>
            {resource.title}
          </Typography>
        </Tooltip>
        <OptionsHandler 
          options={options}
          handleSelect={handleSelect}
        />
      </Box>
    </Card>
  )
}

export const CollectionCard = (props) => {
  const { resource } = props
  const colors = ["193,150,21", "19,141,215", "71,19,215", "215,19,111"]
  const color = colors[simpleHash(resource.name) % colors.length]
  const navigate = useNavigate()
  return (
    <Card className="collection-card">
      <Box
        className="collection-bg" id="collection-bg0"
        sx={{
          backgroundImage: `url("${resource.image ?? musicbg}")`,
          backgroundSize: "cover",
          borderRadius: "8px"
        }}
        onClick={() => {
          navigate(`/My-Collections/${resource.id}`)
        }}
      >
      </Box>
      <Typography
        fontWeight={"700"}
        fontSize="18"
        sx={{ m: "auto", mt: 1, color: "#fff" }}
      >
        {resource.name}
      </Typography>
      <Typography
        fontWeight={"500"}
        fontSize={"16"}
        sx={{ m: "auto", mt: 1, color: "#fff" }}
      >
        {resource.total_sounds + ' songs'}
      </Typography>
    </Card>
  )
}

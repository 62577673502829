import { useState } from "react";

const { Modal, Box } = require("@mui/material")

const Context = () => null
const Content = () => null

const DialogLayout = ({children}) => {
    const [open, setOpen] = useState(false);
    const context = children.find(el => el.type === Context)
    const content = children.find(el => el.type === Content)

    return (
        <>
            {context ? context.props.children({open, setOpen}) : null}
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: "5px",
                    // width: 688,
                    // height: 462,
                    bgcolor: 'background.paper'
                }}>
                    { content ? content.props.children({open, setOpen}) : null }
                </Box>
            </Modal>
        </>
    )
}

DialogLayout.Context = Context
DialogLayout.Content = Content

export default DialogLayout
import { api } from "./api";

export const CollectionAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getCollections: builder.query({
            query: (params) => ({
                url: 'v1/collections',
                method: "GET",
                params: params
            }),
            providesTags: ['Collection'],
            // transformResponse: (response) => response.data,
            subscribe: false,
            forceRefetch: true,
        }),
        getCollectionById: builder.query({
            query: (collectionId) => `v1/collections/${collectionId}`,
            subscribe: false,
            forceRefetch: true,
        }),
        getSoundsFromCollection: builder.query({
            query: (collectionId) => `v1/collections/${collectionId}/sounds`,
            // transformResponse: (response) => response.data,
            subscribe: false,
            forceRefetch: true,
        }),
        deleteSoundFromCollection: builder.mutation({
            query: ({collectionId, soundId}) => ({
                url: `v1/collections/${collectionId}/sounds/${soundId}`,
                method: 'DELETE'
            }),
        }),
        addCollectionSound: builder.mutation({
            query: (body) => ({
                url: "v1/addCollectionSound",
                method: "POST",
                body: body
            }),
        }),
        setCollectionDefault: builder.mutation({
            query: ({collectionId}) => ({
                url: `v1/collections/set-default/${collectionId}`,
                method: 'POST'
            }),
        }),
        getDefaultCollection: builder.query({
            query: () => 'v1/get-default-collection',
            subscribe: false,
            forceRefetch: true,
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetCollectionsQuery,
    useGetCollectionByIdQuery,
    useGetSoundsFromCollectionQuery,
    useDeleteSoundFromCollectionMutation,
    useAddCollectionSoundMutation,
    useSetCollectionDefaultMutation,
    useGetDefaultCollectionQuery,
} = CollectionAPI;
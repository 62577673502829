import { confirmSlice} from "store/confirmSlice";
import { useDispatch, useSelector } from "react-redux";

let resolveCallback;
let rejectCallback;
// callback function to call after click OK button
let confirmCallback;
export default function useConfirm() {
    const confirmState = useSelector(state => state.confirm)
    const dispatch = useDispatch()

    const onConfirm = async () => {
        if (confirmCallback) {
            try {
                dispatch(confirmSlice.actions.setLoadingCallback(true))
                await confirmCallback()
                resolveCallback(true);
            } catch (error) {
                rejectCallback(error)
            } finally {
                dispatch(confirmSlice.actions.setLoadingCallback(false))
                closeConfirm();
            }
        } else {
            resolveCallback(true);
            closeConfirm();
        }
        // reset confirmCallback
        confirmCallback = null
    }

    const onCancel = () => {
        closeConfirm()
        resolveCallback(false)
    }

    const confirm = ({text, title='', callback=(() => {})}) => {
        dispatch(confirmSlice.actions.showConfirm({ text, title }))
        return new Promise((res, rej) => {
            resolveCallback = res;
            rejectCallback = rej;
            confirmCallback = callback;
        })
    }

    const closeConfirm = () => {
        dispatch(confirmSlice.actions.hideConfirm())
    }

    return { confirm, onConfirm, onCancel, closeConfirm, confirmState };
}
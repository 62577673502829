import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    show: false,
    text: '',
    title: '',
    isLoadingCallback: false
}

export const confirmSlice = createSlice({
    name: 'confirm',
    initialState,
    reducers: {
        showConfirm: (state, action) => {
            state.show = true;
            state.text = action.payload?.text ?? '';
            state.title = action.payload?.title ?? '';
        },
        hideConfirm: () => {
            return initialState
        },
        setLoadingCallback: (state, action) => {
            state.isLoadingCallback = action.payload
        }
    }
})
import { Box, styled, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import wavesurfer from "wavesurfer.js";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import OptionsHandler from "./OptionsHandler";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { UseSaveSoundCollectionDialog } from "pages/my-collection/Sounds";
import useDeleteFromCollection from "components/customhook/useDeleteFromCollection";
import musicbg from 'assets/images/music-bg.jpeg'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import SoundSetting from "./SoundSetting";

const SoundWaveStyle = styled(Box)(({ theme }) => ({
  ".MuiAccordion-root": {
    background: "unset",
    boxShadow: "unset",
    ".MuiAccordion-region": {
      ".MuiAccordionDetails-root": {
        padding: "16px"
      },
      background: "#17242d"
    }
  },
  ".soundwave-container": {
    paddingTop: "29px",
    paddingBottom: "24px",
    borderBottom: "1px solid #B7B7B7",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "&:last-child": {
      borderBottom: "none"
    },
  },
  ".MuiTypography-root": {
    color: "#fff",
    fontWeight: "500",
    fontSize: "18px",
  },
  ".thumbnail": {
    backgroundSize: "cover",
    width: "58px",
    height: "58px",
    marginLeft: "22px",
    cursor: "pointer",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "svg": {
      fontSize: "40px"
    }
  }
}))

const SoundWave = (props) => {
  const waveFormRef = useRef()
  const waveSurferRef = useRef();
  const [isPlaying, toggleIsPlaying] = useState(false);
  const { soundInfo, extraInfo: { collectionId } = {}, onRemoveSound, isHistory } = props
  const [waveStyle] = useState(isHistory ? {
    width: "100px",
    height: "50px"
  } : {
    width: "285px",
    height: "50px"
  });

  const options = [
    'Add to default collection',
    'Add to collection'
  ]

  const deleteFromCollection = useDeleteFromCollection({
    soundId: soundInfo.id,
    collectionId: collectionId,
    callback: () => onRemoveSound(soundInfo.id)
  })

  if (collectionId) {
    options.push('Delete from collection')
  }

  const handleSelectFactory = (onSaveClick) => {
    return async (option) => {
      switch (option) {
        case 'Delete from collection':
          await deleteFromCollection()
          break;
        case 'Add to default collection':
          onSaveClick(soundInfo.id, 'add')
          break;
        case 'Add to collection':
          onSaveClick(soundInfo.id, 'collection')
          break;
        default:
          break;
      }
    }
  }

  const handleToggleSound = async (e) => {
    e.stopPropagation()
    // prevent user click too fast when the component is not ready
    if (!waveSurferRef.current) return;
    waveSurferRef.current.playPause();
    toggleIsPlaying(!isPlaying);
  }

  useEffect(() => {
    const waveSurfer = wavesurfer.create({
      container: waveFormRef.current,
      waveColor: '#636685',
      progressColor: '#5D5FEF',
      barWidth: 2,
      barRadius: 2,
      barGap: 4,
      barMinHeight: 1,
      cursorWidth: 2,
      backend: "WebAudio",
      height: 45,
      responsive: true,
      cursorColor: "#FCDDEC"
    })
    waveSurfer.load(soundInfo.link)
    // waveSurfer.load('https://dhf62eo10occ1.cloudfront.net/assets/cyberpunk-gaming-electro-by-infract.mp3')
    waveSurfer.on('ready', () => {
      // if (onFinishLoad) onFinishLoad()
      waveSurferRef.current = waveSurfer;
    });
    waveSurfer.on('finish', () => {
      toggleIsPlaying(false)
    })
    waveSurfer.on('error', (e) => {
      console.log(e)
    })
    return () => {
      waveSurfer.destroy()
    }
  }, [waveFormRef])

  const renderContent = () => {
    return (
      <Box className="soundwave-container">
        <Box
          sx={{
            backgroundImage: `url("${soundInfo.image_link ?? musicbg}")`,
          }}
          className="thumbnail" onClick={handleToggleSound}
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </Box>
        <Box sx={{
          width: "180px"
        }}>
          <Typography>
            {soundInfo.title}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              "&&": {
                fontWeight: "300",
                fontSize: "16px"
              }
            }}
          >
            {soundInfo.length}
            {soundInfo.length < 2 ? ' second' : ' seconds'}
          </Typography>
        </Box>
        <div style={waveStyle} onClick={e => e.stopPropagation()}>
          <div ref={waveFormRef}></div>
        </div>
        {
          isHistory && <Box sx={{
            width: "100px"
          }}>
            <Typography>
              {soundInfo.fb_user_name}
            </Typography>
          </Box>
        }
        {
          !isHistory && <div><Box sx={{ color: "#fff", height: "24px" }}>
          <InsertLinkIcon />
        </Box>
        <Box onClick={e => e.stopPropagation()} sx={{ marginRight: "20px" }}>
          <UseSaveSoundCollectionDialog>
            {onSaveClick => (
              <OptionsHandler
                options={options}
                handleSelect={handleSelectFactory(onSaveClick)}
              />
            )}
          </UseSaveSoundCollectionDialog>
        </Box></div>
        }
      </Box>
    )
  }

  return (
    <SoundWaveStyle>
      {
        !collectionId ?
          renderContent() :
          <Accordion>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {renderContent()}
            </AccordionSummary>
            <AccordionDetails>
              <SoundSetting
                soundInfo={soundInfo}
                collectionId={collectionId}
              />
            </AccordionDetails>
          </Accordion>
      }
    </SoundWaveStyle>
  )
}

export default SoundWave
import { useDeleteSoundFromCollectionMutation } from "api/collectionApi";
import useConfirm from "components/confirm/useConfirm";
import useSnackbar from "components/snackbar/useSnackbar";

/**
 * It takes in a soundId, collectionId, and a callback function. It then uses a mutation to delete the
 * sound from the collection. If the mutation is successful, it calls the callback function.
 * </code>
 * @param props - {
 * @returns A function that is being returned.
 */
const useDeleteFromCollection = (props) => {
    const {soundId, collectionId, callback} = props
    const { confirm } = useConfirm()
    const [deleteSoundFromCollection] = useDeleteSoundFromCollectionMutation();
    const {sendAlert} = useSnackbar()

    const deleteFromCollection = async () => {
        if (!collectionId || !soundId) {
            return
        }
        try {
            let res = await confirm({
                text: "Are you sure to delete this sound from collection?",
                title: "Confirm",
                callback: async () => {
                    await deleteSoundFromCollection({ collectionId, soundId}).unwrap()
                }
            })
            // remove from views
            if (res) {
                sendAlert("Succesfully delete the sound from collection", "success")
                if (callback)
                    callback()
            }
        } catch (e) {
            console.log(e)
            sendAlert("An Unexpected Error Occurred", "error")
        }
    }

    return deleteFromCollection

};

export default useDeleteFromCollection;

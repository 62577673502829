import { Box, Button, Icon, Input, styled, Typography, useTheme } from "@mui/material"
import AudioPlayer from "components/player/audio"
import { useState } from "react"
import UploadIcon from '../../assets/svg/upload.svg'

const SubmitSoundStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: 44,
  '.MuiBox-root': {
    display: 'flex'
  },
  '#upload-btn': {
    backgroundColor: theme.palette.purple.main
  },
  '.nav-btn': {
    borderRadius: '10px',
    px: 3.5,
  }
}))

const SvgIcon = styled(Icon)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
}))

const ButtonText = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 18,
  textTransform: 'capitalize'
}))

const SubmitSound = (props) => {
  const { basicInfo, setBasicInfo, handleSwitchStep } = props
  const addFile = (e) => {
    if (e.target.files[0]) {
      setBasicInfo({
        ...basicInfo, ...{
          audio: e.target.files[0],
          audioSrc: URL.createObjectURL(e.target.files[0])
        }
      })
    }
  }

  const [previewAudio, setPreviewAudio] = useState(Boolean(basicInfo.audio))
  const theme = useTheme()
  return (<>
    <>
      {!previewAudio
        ?
        // this is the component for uploading sound
        (<SubmitSoundStyle>
          <Box sx={{ justifyContent: "center" }}>
            <label htmlFor="icon-button-file">
              <Box sx={{
                mt: 4,
                mb: 3.25,
                cursor: "pointer",
                width: 120,
                height: 120,
                borderRadius: "50%",
                backgroundColor: '#E3E3E3',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                '&:hover': {
                  backgroundColor: '#D5D5D5'
                }
              }}>
                <Input sx={{ display: 'none' }} accept="audio/*"
                  id="icon-button-file" type="file" onChange={addFile} />
                <SvgIcon sx={{ fontSize: '4rem' }}>
                  <img src={UploadIcon} />
                </SvgIcon>
              </Box>
            </label>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: 500, fontSize: 24, m: "auto" }}>
              Drop and drag the video you want to upload
            </Typography>
          </Box>
          <Box sx={{ mb: 4.5 }}>
            <Typography sx={{ fontWeight: 600, fontSize: 16, color: "#92929D", m: "auto" }}>
              Support .mp4, .mov
            </Typography>
          </Box>
          <Box sx={{ mb: 4.5 }}>
            <Button
              disabled={!basicInfo.audioSrc}
              variant="contained"
              sx={{
                mx: 'auto', height: 43, width: 230,
                '&.Mui-disabled': {
                  color: "rgb(185, 185, 185)"
                }
              }}
              id="upload-btn"
              onClick={() => setPreviewAudio(true)}
            >
              Upload File
            </Button>
          </Box>
        </SubmitSoundStyle>)
        // This is the component for preview sound
        : <SubmitSoundStyle>
          <AudioPlayer
            audioSrc={basicInfo.audioSrc}
            handleMetadata={(ref) => {setBasicInfo({
              ...basicInfo, soundLength: ref.duration
            })}}
          />
          <Box sx={{justifyContent: "space-between", mt: 10}}>
            <Button className='nav-btn' size="large" id="btn-back"
              variant="contained" sx={{
                backgroundColor: "#858585",
                "&:hover": {
                  backgroundColor: "#a6a6a6"
                }
              }} onClick={() => {setPreviewAudio(false)}}
            >
              <ButtonText>
                Back
              </ButtonText>
            </Button>
            <Button  className='nav-btn' size="large" id="btn-save"
              variant="contained" sx={{
                backgroundColor: theme.palette.purple.main,
                "&:hover": {
                  backgroundColor: theme.palette.purple.light
                }
              }} onClick={handleSwitchStep(1)}
            >
              <ButtonText>
                Next
              </ButtonText>
            </Button>
          </Box>
        </SubmitSoundStyle>
      }
    </>
  </>)
}

export default SubmitSound
import { useTheme } from "@emotion/react";
import { Box, styled, Slider, Stack, Typography, Button, CircularProgress, TextField, InputBase } from "@mui/material";
import { useAddUserSoundSettingMutation } from "api/soundApi";
import useSnackbar from "components/snackbar/useSnackbar";
import { useState } from "react";
import { useDispatch } from "react-redux";

const SoundSettingStyle = styled(Box)(({ theme }) => ({
  padding: "0px 20px",
  ".small-text": {
    fontSize: "16px",
  }
}))

const debounce = ((callback, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      callback(...args)
    }, delay)
  }
})

const SoundSetting = (props) => {
  const theme = useTheme()
  const {sendAlert} = useSnackbar()
  const dispatch = useDispatch()
  const [addUserSoundSetting, addUserSoundSettingRes] = useAddUserSoundSettingMutation()
  const { soundInfo, collectionId } = props;
  const [isSaving, setIsSaving] = useState(false)
  const [lastSetting, setLastSetting] = useState({
    starAmount: soundInfo.star_amount ? soundInfo.star_amount : 0,
    volume: soundInfo.volume ? soundInfo.volume : 1
  })
  const [volume, setVolume] = useState(lastSetting.volume)
  const [starAmount, setStarAmount] = useState(lastSetting.starAmount)

  const saveSetting = async () => {
    let payload = {
      collection_id: collectionId,
      sound_id: soundInfo.id
    }

    if (volume !== lastSetting.volume) {
      payload.volume = volume
    }

    if (starAmount !== lastSetting.starAmount) {
      payload.star_amount = starAmount
    }

    if(starAmount < 10 && starAmount != 0)
    {
      sendAlert("Please press Star Amount is 0 for free sounds and smallest is 10 for paid sounds", "error")
      return;
    }

    if (volume !== lastSetting.volume ||
        starAmount !== lastSetting.starAmount
      ) {
      try {
        setIsSaving(true)
        let res = await addUserSoundSetting(payload)
        sendAlert('Save setting successfully', 'success')
        setIsSaving(false)
        // reset the form
        setLastSetting({
          starAmount: starAmount,
          volume: volume
        })
      } catch (err) {
        console.log(err)
        sendAlert("An Unexpected Error Occurred", "error")
      }
    }
  }

  const onChangeVolume = (e, val) => {
    setVolume(val / 100)
  }

  const onChangeStarAmount = (e) => {
    let amount = 0
    if (e.target.value && /^[0-9]*$/.test(e.target.value)){
      amount = e.target.value
    }
    // setIsSettingChanged(true)
    setStarAmount(Number(amount))
  }

  // const handleChange = debounce(async (e, val) => {
  //   try {
  //     await addUserSoundSetting({
  //       'collection_id': collectionId,
  //       'sound_id': soundInfo.id,
  //       'volume': val / 100
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }, 500)

  return (<SoundSettingStyle>
    <Stack direction="row" sx={{mb: 2}}>
      <Box sx={{ width: 200 }}>
        <Typography className="small-text">
          Sound
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography className="small-text">
          {soundInfo.title}
        </Typography>
      </Box>
    </Stack>
    <Stack direction="row" sx={{mb: 2}}>
      <Box sx={{ width: 200 }}>
        <Typography className="small-text">
          Sound Volume
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Slider
          aria-label="Always visible"
          getAriaValueText={(valuetext) => `${valuetext}%`}
          value={volume * 100}
          step={10}
          marks
          min={0}
          max={100}
          valueLabelDisplay="auto"
          sx={{
            color: "#80f5d2"
          }}
          onChange={onChangeVolume}
        />
      </Box>
    </Stack>
    <Stack direction="row" alignItems="center" sx={{mb: 2}}>
      <Box sx={{ width: 200 }}>
        <Typography className="small-text">
          Star Amount
        </Typography>
      </Box>
      <Box>
        <TextField
          onChange={onChangeStarAmount}
          sx={{
            borderRadius: "20px",
            backgroundColor: "blue.light",
            "input": {
              color: "#fff",
            },
            "fieldset": {
              border: "none"
            }
          }}
          inputProps={{ inputMode: 'numeric', pattern: '^[0-9]*' }} value={starAmount ? starAmount : 0}/>
      </Box>
    </Stack>
    <Stack direction="row" justifyContent="end">
      <Button
        onClick={saveSetting}
        sx={{
          '&.MuiButton-root': {
            width: '100px',
            backgroundColor: 'orange.light'
          }
        }}
        variant="contained"
        disabled={isSaving}
      >
        {
          isSaving
            ?
            (<CircularProgress size={24} sx={{
              color: '#fff',
            }} />)
            :
            'Save'
        }
      </Button>
    </Stack>
  </SoundSettingStyle>)
}

export default SoundSetting
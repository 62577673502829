import { Backdrop, Box, Button, CircularProgress, Pagination, styled, Typography } from "@mui/material"
import { useBlockViewerMutation, useGetViewerListQuery } from "api/userApi"
import ContentLayout from "layouts/CardContent"
import blankProfile from 'assets/images/blank-profile-picture.png'
import { ITEM_PER_PAGE, PLATFORM_ID } from "helpers/constants"
import useSnackbar from "components/snackbar/useSnackbar"
import { useDispatch } from "react-redux"
import { api } from "api/api"
import { useEffect, useState } from "react"
import { SearchBar } from "components/input"
import useCustomPagination from "components/customhook/usePagination"
import { DateTime } from "luxon"

const BlockListStyle = styled(Box)(({theme}) => ({
  '.MuiTypography-root': {
    color: '#fff'
  }
}))

const formatDate = (date) => {
  return DateTime.fromString(date, 'yyyy-LL-dd hh:mm:ss').toRelative()
}

const BlockList = () => {
  const {data: viewers, isFetching} = useGetViewerListQuery({}, {refetchOnMountOrArgChange: true});
  
  const [blockViewer] = useBlockViewerMutation();
  const {sendAlert} = useSnackbar()
  const [btnLoading, setBtnLoading] = useState('')
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [loadData, setLoadData] = useState([]);
  const {renderPagination, page} = useCustomPagination({loadData})

  const searchForText = (e) => {
    setSearchText(e.target.value)
  }
  
  useEffect(() => {
    if (viewers) {
      setLoadData(viewers.value.filter(
        (viewer) => !searchText || viewer.fb_user_name.includes(searchText)
      ))
    }
  }, [searchText, viewers])

  const handleBlockViewer = async (body) => {
    try {
      let viewer_name = body.viewer_name
      let is_block = body.is_block
      setBtnLoading(viewer_name)
      await blockViewer(body).unwrap()
      sendAlert(`User ${is_block ? 'blocked' : 'unblocked'}`, 'success')
      dispatch(api.util.updateQueryData('getViewerList', {}, (viewers) => {
        viewers.value.forEach((item) => {
          if (item.fb_user_name === viewer_name) {
            item.is_block = is_block
          }
        })
      }))
    } catch (e) {
      sendAlert('Something unexpected happened!', 'error')
    } finally {
      setBtnLoading('')
    }

  }

  return (
  isFetching ?
  <Backdrop
    sx={{
      color: "#fff",
      zIndex: (theme) => theme.zIndex.drawer - 1,
      position: "absolute",
      opacity: 0.5,
    }}
    open={true}
  >
    <CircularProgress
      color="inherit"
      size={24}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: "-12px",
        marginLeft: "-12px",
      }} />
  </Backdrop> :
  <BlockListStyle>
    <ContentLayout>
      <ContentLayout.Title>
        <Typography
          className="card-title"
        >
          Block User Review List
        </Typography>
      </ContentLayout.Title>
      <ContentLayout.Content>
        <Box sx={{display: 'flex', pt: 1}}>
          <SearchBar 
            placeholder="Search user"
            searchForText={searchForText}
          />
        </Box>

        <Box sx={{
          paddingLeft: "20px"
        }}>
          <Box sx={{display: 'flex', padding: "10px"}}>
            <Box
                sx={{
                  width: "108px",
                  mr: 2
                }}
            >
              <Typography>
                Profile Picture
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                width: "14ch",
                alignItems: 'center'
              }}>
                <Typography>
                  Username
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                width: "20ch",
                alignItems: 'center'
              }}>
                <Typography>
                  User Id
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                width: "18ch",
                alignItems: 'center'
              }}>
                <Typography>
                  Total Sounds Given
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                width: "16ch",
                alignItems: 'center',
              }}>
                <Typography>
                  Most Recent Sent
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                width: "170px",
                alignItems: 'center'
              }}>
                <Typography>
                  Action
                </Typography>
            </Box>
          </Box>
          {loadData.slice((page-1) * ITEM_PER_PAGE, page * ITEM_PER_PAGE).map(viewer => (
            <Box sx={{display: 'flex', padding: "10px"}}>
              <Box sx={{
                width: "108px",
                mr: 2,
                // display: "flex",
                // justifyContent: "center"
              }}>  
                <Box
                  sx={{
                    backgroundImage: `url("${viewer.image_link ?? blankProfile}")`,
                    backgroundSize: "cover",
                    width: "58px",
                    height: "58px",
                  }}
                >
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                width: "14ch",
                alignItems: 'center'
              }}>
                <Typography>
                  {viewer.fb_user_name}
                </Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                width: "20ch",
                alignItems: 'center'
              }}>
                <Typography>
                  {viewer.fb_user_id}
                </Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                width: "18ch",
                alignItems: 'center'
              }}>
                <Typography>
                  {viewer.total_play} time(s)
                </Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                width: "16ch",
                alignItems: 'center'
              }}>
                <Typography>
                  {formatDate(viewer.last_play)}
                </Typography>
              </Box>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Button sx={{
                  '&.MuiButton-root': {
                    width: '170px',
                    backgroundColor: viewer.is_block ? 'purple.main' : 'orange.light'
                  }
                }}
                  variant="contained"
                  onClick={() => handleBlockViewer({
                    viewer_id: viewer.fb_user_id,
                    viewer_name: viewer.fb_user_name,
                    is_block: !viewer.is_block ? 1 : 0,
                    platform_id: PLATFORM_ID.FACEBOOK
                  })}
                  disabled={viewer.fb_user_name === btnLoading}
                >
                  {
                    viewer.fb_user_name === btnLoading ?
                    <CircularProgress size={24} sx={{
                      color: '#fff',
                    }}/> :
                    <Typography sx={{fontSize: '14px'}}>
                      {viewer.is_block ? 'Unblock this user' : 'Block this user'}
                    </Typography>
                  }
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{display: 'flex'}}>
          {renderPagination}
        </Box>
      </ContentLayout.Content>
    </ContentLayout>
  </BlockListStyle>)
}

export default BlockList
import { useState } from "react"

export const WithAudio = Component => {
    return function WithAudio(props) {
        const [audioSrc, setAudioSrc] = useState('')

        return (
            <>
                <Component {...props} audioSrc={audioSrc} setAudioSrc={setAudioSrc} />
                {
                    audioSrc &&
                    (<video
                        style={{ display: "none" }}
                        key={audioSrc}
                        controls
                        autoPlay={true}
                        className="sound-player"
                        onEnded={() => { setAudioSrc('') }}
                    >
                        <source src={audioSrc}></source>
                    </video>)
                }
            </>
        )
    }
}
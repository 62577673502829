import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { useSetObsIdMutation } from "api/userApi"
import useSnackbar from "components/snackbar/useSnackbar"
import {useState} from 'react'
import { useDispatch } from "react-redux"
import { api } from "api/api";
import { FacebookButton } from "../facebook"
import axios from "axios"
import { FB_GRAPH_API_URL } from "helpers/constants"
import { useRef } from "react"
import { useEffect } from "react"

const FBPageConnectDialog = (props) => {
    const {
        open,
        onClose,
        pageData
    } = props

    const dispatch = useDispatch() 
    const [selectedPage, setSelectedPage] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const {sendAlert} = useSnackbar()
    const [setObsId, setObsIdRes] = useSetObsIdMutation()
    let isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    const onSaving = async () => {
        try {
            setIsSaving(true)
            await setObsId({fb_page_id: selectedPage}).unwrap()
            dispatch(api.util.updateQueryData('getObsId', {}, (res) => {
                res.value = selectedPage
            }))
            dispatch(api.util.updateQueryData('getUser', 'self', (res) => {
                res.value.obs_id = selectedPage
            }))
            sendAlert('Save page successfully!', 'success')
        } catch (e) {
            console.log(e)
            sendAlert('Cannot save selected page', 'error')
        } finally {
            if (isMounted.current) {
                setIsSaving(false)
                onClose()
            }
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle sx={{fontWeight: 600}}>
                Choose a page you want to live stream on
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <FormLabel>Select one of these page below</FormLabel>
                    <RadioGroup
                        aria-labelledby="page-list"
                        name="fb-page-list"
                        
                    >
                        {pageData.map((page) => (
                            <FormControlLabel
                                key={page.id}
                                value={page.id}
                                control={<Radio />}
                                label={page.name}
                                onChange={() => setSelectedPage(page.id)}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions
                sx={{display: 'flex', justifyContent: 'space-between', px: "22px"}}
            >
                <Button
                    variant='contained' color='grey' sx={{borderRadius: 10, mb:3}}
                    onClick={onClose}>
                    <Typography sx={{color: '#fff', textTransform:'capitalize', fontWeight: 500, fontSize: 16, px: 2}}>
                        Cancel
                    </Typography>
                </Button>
                <Button variant='contained' color='purple' sx={{borderRadius: 10, mb:3}}
                    disabled={!selectedPage}
                    onClick={onSaving}
                >
                    {
                        isSaving ?
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#fff",
                                mr: "10px",
                                mx: "30px"
                            }}
                        /> : 
                        <Typography sx={{color: '#fff', textTransform:'capitalize', fontWeight: 500, fontSize: 16, px: 2}}>
                            Save
                        </Typography>
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const FBPageConnectButton = (props) => {
    const {sx} = props
    const [FBPageData, setFBPageData] = useState([])
    const [openFBPageDialog, setOpenFBPageDialog] = useState(false)

    const onFacebookLoginFail = () => {

    }

    const FBPageConnectCallback = async (response) => {
        if (response.accessToken) {
            let requestFbLink = `${FB_GRAPH_API_URL}${response.userID}/accounts?access_token=${response.accessToken}`;
            let pageData = [];
            while(requestFbLink != '' && requestFbLink != null && requestFbLink != undefined)
            {
                let res = await axios.get(`${FB_GRAPH_API_URL}${response.userID}/accounts?access_token=${response.accessToken}`);
                let data = res.data.data;
                pageData = pageData.concat(data);
                if(res.data.paging && res.data.paging.next)
                {
                    requestFbLink = res.data.paging.next;
                }
                else
                {
                    requestFbLink = '';
                }
            }
            console.log(pageData)
            setFBPageData(pageData)
            setOpenFBPageDialog(true)
        }
    }

    return (
        <>
            <FacebookButton
                onFailure={onFacebookLoginFail}
                textButton="Connect FB page"
                customCallback={ FBPageConnectCallback }
                sx={
                    sx ? sx : {}
                }
            />
            <FBPageConnectDialog
                open={openFBPageDialog}
                onClose={() => setOpenFBPageDialog(false)}
                pageData={FBPageData}
            />
        </>
    )
}

export {
    FBPageConnectDialog,
    FBPageConnectButton
}
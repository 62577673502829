import React, { useState } from "react";
import {
  Box,
  Paper,
  Chip,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Button
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Collections } from "./Collections";
import { Sounds } from './Sounds'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./index.scss";
import { useGetCollectionsQuery } from "api/collectionApi";
import SoundGridLayout from "layouts/soundsGrid";
import ContentLayout from "layouts/CardContent";
import { useNavigate } from "react-router-dom";
import UseCreateCollectionDialog from "pages/create-collection/CreateCollectionDialog";
import UseUploadSoundDialog from "pages/create-sound/SubmitSoundDialog";

const MyCollection = () => {
  const {data: collections, isFetching} = useGetCollectionsQuery({}, {refetchOnMountOrArgChange: true});
  const [activeCollection, setActiveCollection] = useState(0)
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate()
  const searchForText = (e) => {
    setSearchText(e.target.value)
  }

  return (
    <Box id="my-library" sx={{width:"100%"}}>
      <ContentLayout>
        <ContentLayout.Title>
          <Typography
            gutterBottom
            className="card-title"
          >
            My Collections
          </Typography>
          <UseCreateCollectionDialog>
            {(openDialog) => (
              <Button
                className="add-collection-button"
                variant="contained"
                onClick={() => openDialog()}
              >
                Create new Collection
              </Button>
            )}
          </UseCreateCollectionDialog>
          {/* <UseUploadSoundDialog>
            {(openDialog) => (
              <Button
                className="add-collection-button"
                variant="contained"
                onClick={() => openDialog()}
              >
                Create new Sound
              </Button>
            )}
          </UseUploadSoundDialog> */}
        </ContentLayout.Title>
        <ContentLayout.Content>
          <Box
            sx={{
              display: "grid",
              gap: "35px",
              gridTemplateColumns: "repeat(auto-fill, minmax(192px, 1fr))",
              placeItems: "center",
              padding: "30px 27px",
            }}
          >
            <Collections
              setActiveCollection={setActiveCollection}
              searchText={searchText}
              collections={collections}
              isFetchingCollections={isFetching}
            />
          </Box>
        </ContentLayout.Content>
      </ContentLayout>
      {/* <Box className="filter-bar">
        <Box className="left-bar">
          {
            activeCollection === 0
            ? <>
              <Chip
                label="Collections"
                variant="outlined"
              />
              <Chip
                label="Sounds"
                variant="outlined"
              />
              </>
            : <>
                <IconButton onClick={() => {setActiveCollection(0)}}>
                  <ArrowBackIcon/>
                </IconButton>
                <Typography fontFamily={"Poppins"} sx={{m: "auto"}}>Go back</Typography>
              </>
          }
        </Box>
        <Box className="search-bar">
          <TextField
            label="Search user library"
            size="small"
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
            }}
            variant="standard"
            onChange={searchForText}
          />            
        </Box>
      </Box> */}
      {/* <Paper elevation={3} >
        <SoundGridLayout>
          {
            activeCollection === 0
            ? <Collections
                setActiveCollection={setActiveCollection}
                searchText={searchText}
                collections={collections}
                isFetchingCollections={isFetching}
              />
            : <Sounds
              collectionId={activeCollection}
              searchText={searchText}
            />
          }
        </SoundGridLayout>
      </Paper> */}
    </Box>
  );
};

export default MyCollection;

import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { Alerts } from "../../components/alert";
import "./styles.scss";
import { useSetCollectionDefaultMutation, useGetDefaultCollectionQuery, useGetCollectionsQuery } from "api/collectionApi";
import { useGetObsIdQuery, useGenerateFanCodeMutation, useSetObsIdMutation } from "api/userApi";
import { FacebookButton } from "components/social/facebook";
import SettingsIcon from '@mui/icons-material/Settings';
import SoundGridLayout from "layouts/soundsGrid";
import { SoundsGrid } from "pages/my-collection/Sounds";
import { useGetMostPlaySoundListQuery } from "api/soundApi";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentLayout from "layouts/CardContent";
import {FBPageConnectButton} from "components/social/dialog/fb-page-connect"
import { NoResult } from "components/common/NoResult";
import useLoadMore from "components/customhook/useLoadMore";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const Home = () => {
  // const dispatch = useDispatch() 
  const [isloading, setIsLoading] = useState(false);
  const [alert, setAlert] = React.useState("");
  const [openDialogCollection, setOpenDialogCollection] = useState(false);
  const [collectionSelected, setCollectionSelected] = useState(null);
  const collections = useGetCollectionsQuery({}, { refetchOnMountOrArgChange: true });
  const getDefaultCollection = useGetDefaultCollectionQuery({}, { refetchOnMountOrArgChange: true, cacheTime: 0 });
  const getMostPlaySoundList = useGetMostPlaySoundListQuery({}, { refetchOnMountOrArgChange: true });
  const obsId = useGetObsIdQuery({}, { refetchOnMountOrArgChange: true });
  const [obsLink, setObsLink] = useState('');

  // handle load more logic
  const {loadData, renderButtonLoadMore} = useLoadMore({
    numPerLoad: 10,
    srcData: getMostPlaySoundList?.data?.data
  })

  const theme = useTheme()

  useEffect(() => {
    if (!obsId.isFetching) {
      setObsLink(`${process.env.REACT_APP_BASE_URL}/obs/` + obsId?.data?.value);
    }
  }, [obsId]);

  const [collectionDefault, setCollectionDefault] = useState('');

  useEffect(() => {
    if (!getDefaultCollection.isFetching) {
      setCollectionDefault(getDefaultCollection?.data?.data[0].name);
    }
  }, [getDefaultCollection]);

  const copyObsLinkToClipboard = async () => {
    setAlert("");
    await navigator.clipboard.writeText(obsLink);
  };

  const onCollectionChange = (value) => {
    setCollectionSelected(value.target.value);
  }

  const [setDefualtCollection] = useSetCollectionDefaultMutation();
  const saveData = async () => {
    setIsLoading(true);
    try {
      setOpenDialogCollection(false);
      if (collectionSelected !== 0) {
        const result = await setDefualtCollection({ collectionId: collectionSelected }).unwrap();
        if (result.success) {
          const item = collections.data?.data?.find((element) => {
            return element.id === collectionSelected;
          });
          setCollectionDefault(item?.name);
          setAlert(<Alerts msg="Set default collection success" type="success" />);
        }
        else {
          setAlert(<Alerts msg={result.error} type="error" />);
        }
      }
      setCollectionSelected(0);
    }
    catch (error) {
      setAlert(<Alerts msg={error.data.error.message} type="error" />);
    }
    setIsLoading(false);
  }

  const onFacebookLoginFail = (res) => {
    //setMessage('')
    //setTimeout(() => {
    //  setMessage('Unauthorized!')
    //}, 100)
  }

  const [generateFanCode, generateFanCodeRes] = useGenerateFanCodeMutation();
  const [fanCode, setFanCode] = useState('');
  const generateFanCodeClick = async () => {
    setIsLoading(true);
    try {
      const result = await generateFanCode().unwrap();
      if (result.success) {
        setFanCode(result.value);
      }
      else {
        setAlert(<Alerts msg={result.error} type="error" />);
      }
    }
    catch (e) {
      setAlert(<Alerts msg={e.data.error.message} type="error" />);
    }
    setIsLoading(false);
  }

  const copyFanCodeToClipboard = async () => {
    setAlert("");
    await navigator.clipboard.writeText(fanCode);
  };

  const [currentTab, setCurrentTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // hanlde select facebook page
  // const [setObsId, setObsIdRes] = useSetObsIdMutation();
  /*const FBConnectCallback = async (response) => {
    const result = await connectSocial({access_token: response.accessToken,  platform: "facebook"}).unwrap();
    if (result.success) {
      setShowConnectSocial(false);
    }
  }*/
  const [setObsId, setObsIdRes] = useSetObsIdMutation();

  return (
    <Box id="homepage">
      <Grid className="social-media-connect" >
        <ContentLayout>
          <ContentLayout.Title>
            <Typography
              gutterBottom
              className="card-title"
            >
              Setting up OBS
            </Typography>
          </ContentLayout.Title>
          <ContentLayout.Content>
            <CardContent sx={{ pl: 3, pt: 1.5 }}>
              
              {/* {showConnectSocial &&
                <FacebookButton
                  onFailure={onFacebookLoginFail}
                  textButton="Connect"
                  customCallback={FBConnectCallback}
                />
              } */}
              
              {/* copy link to obs */}
              <Box className="detail-container">
                <Typography className="detail-label">
                  OBS Link
                </Typography>
                <InputBase
                  className="detail-info"
                  inputProps={{ "aria-label": "OBS link" }}
                  value={ obsId?.data?.value ? obsLink : 'Please connect your Facebook page'}
                  readOnly
                  disabled={!obsId?.data?.value}
                />
                {
                  obsId?.data?.value
                  ?
                    <Tooltip title="Click to copy obs link">
                      <Button
                        className="detail-button"
                        variant="contained"
                        startIcon={<ContentCopyIcon />}
                        onClick={() => copyObsLinkToClipboard()}
                      >
                        Copy
                      </Button>
                    </Tooltip>
                  :
                    <FBPageConnectButton
                      sx={{
                        minWidth: "253px",
                        backgroundColor: 'purple.main',
                        height: "50px",
                        padding: "0px 30px 0px 30px",
                        margin: "0px 0px 0px 10px",
                        borderRadius: "30px",
                        marginLeft: "10px",
                        '&.MuiButton-contained:hover': {
                          backgroundColor: 'purple.dark',
                        },
                        ".MuiTypography-root": {
                          fontSize: "0.875rem"
                        }
                      }}
                    />
                }
              </Box>
              {/* change default collection */}
              <Box className="detail-container">
                <Typography className="detail-label">
                  Default Collection
                </Typography>
                <InputBase
                  className="detail-info"
                  inputProps={{ "aria-label": "default collection" }}
                  value={collectionDefault}
                  readOnly
                />
                <Tooltip title="Click to change default collection">
                  <Button
                    className="detail-button"
                    variant="contained"
                    startIcon={<SettingsIcon />}
                    onClick={() => setOpenDialogCollection(true)}
                  >
                    Change collection
                  </Button>
                </Tooltip>
              </Box>
              {/* generate fancode */}
              <Box className="detail-container">
                <Typography className="detail-label">
                  Fans Code:
                </Typography>
                <InputBase
                  className="detail-info"
                  inputProps={{ "aria-label": "generate fancode" }}
                  value={fanCode}
                  readOnly
                />
                <Tooltip title="Click to copy Fans Code">
                  <Button
                    className="detail-button"
                    variant="contained"
                    startIcon={<ContentCopyIcon />}
                    onClick={() => copyFanCodeToClipboard()}
                  >
                    Copy
                  </Button>
                </Tooltip>
                <Tooltip title="Click to generate Fans code">
                  <Button
                    className="detail-button"
                    variant="contained"
                    startIcon={<RefreshIcon />}
                    onClick={() => generateFanCodeClick()}
                  >
                    Refresh
                  </Button>
                </Tooltip>
              </Box>
            </CardContent>
          </ContentLayout.Content>
        </ContentLayout>
      </Grid>
      <Grid>
        <ContentLayout>
          <ContentLayout.Title>
            <Typography
              gutterBottom
              className="card-title"
              sx={{ mb: 0 }}
            >
              Explore Sound
            </Typography>
          </ContentLayout.Title>
          <ContentLayout.Content>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={currentTab} onChange={handleChangeTab}
                aria-label="sounds tab" className="sounds-tab-panel"
                variant="fullWidth"
                color="#fff"
              >
                <Tab className="sounds-tab" label="Trending" />
                <Tab className="sounds-tab" label="Popular" />
                <Tab className="sounds-tab" label="Most Used" />
                <Tab className="sounds-tab" label="Favourite" />
              </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
              <Box id='category-sound'>
                <SoundGridLayout>
                  <SoundsGrid
                    sounds={loadData}
                    isFetching={getMostPlaySoundList?.isFetching}
                  />
                </SoundGridLayout>
                {
                  renderButtonLoadMore
                }
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <NoResult />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <NoResult />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <NoResult />
            </TabPanel>
          </ContentLayout.Content>
        </ContentLayout>
      </Grid>
      {alert}
      <Dialog open={openDialogCollection} onClose={() => { setOpenDialogCollection(false); }}>
        <DialogTitle>Choose a collection</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Add to collection"}
          </DialogContentText>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="collection-name-label">Select one</InputLabel>
            <Select
              labelId="collection-selected"
              id="collection-selected"
              value={collectionSelected}
              onChange={onCollectionChange}
              input={<OutlinedInput label="name" />}
              loading={collections.isFetching}
            >
              {collections.data?.data.map((collection) => (
                <MenuItem
                  key={collection.id}
                  value={collection.id}
                  name={collection.name}
                >
                  {collection.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { saveData(false); }}>Save</Button>
          <Button onClick={() => { setOpenDialogCollection(false); }}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={Boolean(isloading || obsId.isFetching || getDefaultCollection.isFetching || generateFanCodeRes.isFetching)}
      >
        <CircularProgress
          color="inherit"
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }} />
      </Backdrop>
    </Box>
  );
};

export default Home;

import { useUploadCollectionMutation } from "api/uploadApi";
import DialogLayout from "layouts/Dialog";
import { useState } from "react";
import UploadCollection from "./UploadCollection";


const UseCreateCollectionDialog = ({ children }) => {

  const [collectionInfo, setCollectionInfo] = useState({
    collectionName: '',
    description: '',
    visibility: 0,
  })

  const [uploadCollection] = useUploadCollectionMutation();

  const submitCollection = async () => {
    // send api to create collection
    return await uploadCollection({
      name: collectionInfo.collectionName,
      description: collectionInfo.description,
      visibility: collectionInfo.visibility
    }).unwrap();
  }

  return (
    <>
      <DialogLayout>
        <DialogLayout.Context>
          {/* pass setOpen to child component */}
          { ({setOpen}) => children(() => setOpen(true))}
        </DialogLayout.Context>
        <DialogLayout.Content>
        { ({setOpen}) => <UploadCollection
            collectionInfo={collectionInfo}
            setCollectionInfo={setCollectionInfo}
            submitCollection={submitCollection}
            onClose={() => setOpen(false)}
          />
        }
        </DialogLayout.Content>
      </DialogLayout>
    </>
  )
}

export default UseCreateCollectionDialog
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useLocation, useParams } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function RouterBreadcrumbs() {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  let { id } = useParams();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //marginTop: "30px",
        marginBottom: "13px",
      }}
    >
      <Breadcrumbs
        separator={<FiberManualRecordIcon sx={{ fontSize: "10px" }} />}
        aria-label="breadcrumb"
      >
        <Typography
              color="text.primary"
              //key="/"
              style={{
                padding: "2px 16px",
                //background: "#1267FC",
                borderRadius: "10px",
                color: "white",
                fontWeight: "500",
                textDecoration: "none",
                fontSize: "30px"
              }}
            >
              {id ? pathNames[pathNames.length - 2] : pathNames[pathNames.length - 1]}
        </Typography>
      </Breadcrumbs>
    </Box>
  );
}

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router";
import { selectAccessToken } from "../store/userSlice";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import { CssBaseline, Container } from "@mui/material";
import RouterBreadcrumbs from "./Breadcrumbs/Breadcrumbs";
import { logout } from "../store/userSlice";
import "./styles.scss";
import jwtDecode from "jwt-decode";
import MenuBar from "./menuBar";

function DashboardLayout() {
  const dispatch = useDispatch();
  const handleLogoutUser = () => {
    dispatch(logout());
  };
  const [open, setOpen] = useState(true);
  const accessToken = useSelector(selectAccessToken);
  if (!accessToken || jwtDecode(accessToken).exp < Date.now() / 1000) {
    handleLogoutUser();
    return <Navigate to="/sign-in" replace />;
  }
  return (
    <Box className="main-container">
      <CssBaseline />
      {/*<Header open={open} setOpen={setOpen} drawerWidth={drawerWidth}></Header>*/}
      <MenuBar open={open} setOpen={setOpen}></MenuBar>
      <Container
        maxWidth={false}
        style={{minHeight: "100vh" }}
      >
        {/* <RouterBreadcrumbs /> */}
        <Outlet />
      </Container>
    </Box>
  );
}

export default DashboardLayout;

import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";

const { ITEM_PER_PAGE } = require("helpers/constants");

const useCustomPagination = (props) => {
    const {loadData} = props 
    const [page, setPage] = useState(1);

    const handleChange = (event, value) => {
        setPage(value)
    };
    
    useEffect(() => {
        setPage(1)
    }, [loadData])

    const renderPagination = (
        <Pagination sx={{
            padding: 2,
            ml: 'auto',
            ".MuiPaginationItem-root": {
              color: "#fff"
            }
          }} count={Math.ceil(loadData.length / ITEM_PER_PAGE)} 
            onChange={handleChange} page={page}
            variant="outlined" shape="rounded"
        />
    )


    return {renderPagination, page, setPage}
}

export default useCustomPagination
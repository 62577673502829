import { Backdrop, Box, Breadcrumbs, CircularProgress, styled, Typography } from "@mui/material";
import { api } from "api/api";
import { useGetCollectionByIdQuery, useGetSoundsFromCollectionQuery } from "api/collectionApi";
import SoundWave from "components/sounds/SoundWave";
import ContentLayout from "layouts/CardContent";
import { useState } from "react";
import { useDispatch } from "react-redux";
const { useParams, Link } = require("react-router-dom");

const MyCollectionDetails = () => {
  const { id } = useParams();
  let { data: sounds, isFetching: fetchingSounds } = useGetSoundsFromCollectionQuery(id, { refetchOnMountOrArgChange: true });
  let { data: collection, isFetching: fetchingCollection } = useGetCollectionByIdQuery(id, { refetchOnMountOrArgChange: true });
  const dispatch = useDispatch()
  const removeSound = (soundId) => {
    dispatch(api.util.updateQueryData('getSoundsFromCollection', id, (sounds) => {
        sounds.data = sounds.data.filter((item) => {
            return item.id !== soundId
        })
    }))
  }

  const breadcrumbs = [
    <Link to="/My-Collections">
      <Typography
        gutterBottom
        className="card-title"
      >
        My Collection
      </Typography>
    </Link>,
    <Typography
      gutterBottom
      className="card-title"
    >
      {collection?.data?.[0]?.name}
    </Typography>
  ]

  const MyCollectionDetailsStyle = styled(Box)(({ theme }) => ({
    ".MuiBreadcrumbs-separator": {
      color: "#fff"
    },
    ".MuiBreadcrumbs-li": {
      ".card-title": {
        padding: "13px 5px 13px 5px",
      }
    },
    ".MuiBreadcrumbs-li:first-of-type": {
      ".card-title": {
        padding: "13px 5px 13px 27px",
        color: theme.palette.purple.light
      }
    },
  }))

  return (
    (fetchingSounds || fetchingCollection) ?
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={fetchingSounds || fetchingCollection}
      ><CircularProgress color="inherit" />
      </Backdrop> :
      <MyCollectionDetailsStyle className="my-collection-details">
        <ContentLayout>
          <ContentLayout.Title>
          <Typography
            gutterBottom
            className="card-title"
          >
            {collection?.data?.[0]?.name}
          </Typography>
          </ContentLayout.Title>
          <ContentLayout.Content>
            {
              sounds && sounds.data.map((item) => (
                <SoundWave
                  key={item.id}
                  soundInfo={item}
                  extraInfo={{
                    collectionId: id
                  }}
                  onRemoveSound={removeSound}
                />
              ))
            }
          </ContentLayout.Content>
        </ContentLayout>
      </MyCollectionDetailsStyle>
  )
}

export default MyCollectionDetails
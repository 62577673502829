import { Slider, Stack, Typography } from "@mui/material"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useRef, useState } from "react";

const { default: styled } = require("@emotion/styled")
const { Box } = require("@mui/system")

const AudioPlayerStyle = styled(Box)(({ theme }) => ({
  backgroundColor: '#a5a6f680',
  alignItems: 'center',
  height: 98,
  borderRadius: 15,
  '.play-btn': {
    cursor: 'pointer',
    fontSize: 32,
    color: "#fff",
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.purple.main,
    padding: 6,
    '.MuiSvgIcon-root': {
      width: 45,
      height: 45,
    }
  },
  'audio': {
    display: 'none'
  }
}))

const TimeText = styled(Typography)({
  fontWeight: 400,
  fontSize: 16,
  color: '#858585',
  width: '7ch',
  textAlign: 'center',
})


const AudioPlayer = (props) => {
  const { audioSrc, handleMetadata=()=>{} } = props
  const audioRef = useRef()
  const [duration, setDuration] = useState(0)
  const [paused, setPaused] = useState(true)
  const [position, setPosition] = useState(0);

  const formatDuration = (value) => {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.floor(value) - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const onLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration)
      handleMetadata(audioRef.current)
    }
  }

  const onTimeUpdate = (val) => {
    if (audioRef.current) {
      const curTime = audioRef.current.currentTime
      if (curTime - position > 0.3) {
        setPosition(curTime)
      }
    }
  }

  const toggleAudio = () => {
    const audio = audioRef.current
    audio.volume = 0.1
    if (paused) {
      audio.play()
    } else {
      audio.pause()
    }
    setPaused(!paused)
  }

  const onChange = (val) => {
    audioRef.current.currentTime = val
    setPosition(val)
  }

  return (
    <AudioPlayerStyle className="custom-audio-player">
      <audio
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onTimeUpdate={onTimeUpdate}
        onEnded={() => { setPosition(0); setPaused(true) }}
      >
        <source src={audioSrc} />
      </audio>
      <Stack
        direction="row"
        spacing={2}
        sx={{ width: '100%', alignItems: 'center', mx: 1.5 }}
      >
        <Box
          className="play-btn"
          onClick={toggleAudio}
        >
          {paused
            ? <PlayArrowIcon />
            : <PauseIcon />
          }
        </Box>
        <TimeText variant='span'>{formatDuration(position)}</TimeText>
        <Slider
          aria-label="time-indicator"
          size="small"
          min={0}
          max={duration}
          value={position}
          step={1}
          onChange={(_, value) => onChange(value)}
          sx={{
            marginLeft: 0,
            height: 8,
            color: '#fff',
            '&.MuiSlider-track': {
            },
            '& .MuiSlider-thumb': {
              height: 23,
              width: 23,
              '&:hover, &.Mui-focusVisible, &.Mui-active': {
                boxShadow: "0px 0px 0px 8px rgba(255, 255, 255, 0.16)"
              },
              // '&.Mui-active': {
              // }
            },
            '& .MuiSlider-rail': {
              color: '#858585',
              opacity: 'unset',
              borderRadius: 5,
            },
          }}
        />
        <TimeText variant='span'>{formatDuration(duration - position)}</TimeText>
      </Stack>
    </AudioPlayerStyle>
  )
}

export default AudioPlayer

import {
  Box,
  Typography,
  TextField,
  Button,
  styled,
  Input,
  CircularProgress
} from '@mui/material'
import { VisibilityRadioInput } from 'components/input'
import { useState } from 'react'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import useSnackbar from 'components/snackbar/useSnackbar';

const UploadCollectionStyle = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  margin: "36px 45px",
  borderRadius: 5,
  "&>.MuiBox-root": {
    display: "flex",
  },
  ".MuiButton-root": {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  '.MuiTextField-root': {
    "& .MuiOutlinedInput-root": {
      '&:hover:not(.Mui-error) fieldset': {
        borderColor: '#B4B4B4',
      },
      '&.Mui-focused:not(.Mui-error) fieldset': {
        borderColor: '#B4B4B4',
      },
    },
    "& label:not(.Mui-error)": {
      color: '#00000099'
    },
  },
  ".MuiFormControlLabel-root": {
    ".Mui-checked": {
      color: theme.palette.purple.main,
      "&+.MuiTypography-root": {
        color: theme.palette.purple.main
      }
    },
    ".MuiFormControlLabel-label": {
      fontWeight: 500,
      fontSize: "16px",
      color: "#838383"
    },
  },
  ".upload-image": {
    width: "227px",
    height: "246px",
    backgroundColor: "#E3E3E3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundSize: "cover",
    "#btn-upload-image": {
      display: "none"
    }
  },
  ".header-title": {
    color: "#757575"
  },
  ".label-title": {
    fontSize:"16px",
    fontWeight: 600,
    color: "#858585"
  },
  "#btn-back": {
    backgroundColor: "#858585",
    "&:hover": {
      backgroundColor: "#a6a6a6"
    }
  },
  "#btn-save": {
    backgroundColor: theme.palette.purple.main,
    "&:hover": {
      backgroundColor: theme.palette.purple.light
    }
  },
}))

let UploadCollection = (props) => {
  const {
    collectionInfo,
    setCollectionInfo,
    submitCollection,
    onClose
  } = props

  // handle validation
  const [validation, setValidation] = useState({
    collectionName: ''
  })

  // handle display alert message
  const {sendAlert} = useSnackbar()

  // handle submit
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onSubmitCollection = async () => {
    setIsSubmitting(true)
    if (!collectionInfo.collectionName) {
      sendAlert('Missing collection name', 'error')
      return
    }

    try {
      await submitCollection()
      // succesful message
      sendAlert('Successfully create a new collection', 'success')
    } catch (e) {
      console.log(e)
      sendAlert('An Unexpected Error Occurred', 'error')
    } finally {
      setIsSubmitting(false)
      onClose()
    }
  }

  // handleChangeCollectionName
  const onChangeCollectionName = (e) => {
    let newCollectionName = e.target.value
    setCollectionInfo({...collectionInfo, collectionName: newCollectionName})
    setValidation({...validation, collectionName: (newCollectionName ? '': 'This field is required')})
  }

  // handle cover image
  const [image, setImage] = useState(null)
  const addImage = (e) => {
    if (e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]))
    }
  }


  return (
    <>
      <UploadCollectionStyle
        className="upload-collection"
      >
        <Typography className="header-title" sx={{
          fontSize:"24px", mb:5,
          mx: "auto", fontWeight: 700
        }}>
          Create Collection
        </Typography>
        <Box>
          <Box>
            <label htmlFor="btn-upload-image">
              <Box className="upload-image" sx={{
                "&.upload-image": {
                  backgroundImage: image ? `url("${image}")` : "unset",
                }
              }}>
                <Input accept="image/*" id="btn-upload-image" type="file" onChange={addImage}/>
                <Box sx={{display: image ? 'none': 'flex', alignItems: "center", flexDirection: "column"}}>
                  <CameraAltIcon sx={{fontSize: "48px", marginBottom: "10px", color: "#92929D"}}/>
                  <Typography sx={{fontWeight: "500", fontSize: "18px", color: "#757575"}}>
                    Upload Cover
                  </Typography>
                </Box>
              </Box>
            </label>
          </Box>
          <Box sx={{ml:2}}>
            <Typography className="label-title">
              Audio Title
            </Typography>
            <TextField
              error={validation.collectionName !== ''}
              helperText={validation.collectionName || ' '}
              fullWidth
              required
              id="collection-name"
              label={collectionInfo.collectionName === '' ? "Add Title": ''}
              value={collectionInfo.collectionName}
              onChange={onChangeCollectionName}
              onBlur={onChangeCollectionName}
              InputLabelProps={{shrink: false}}
            />
            <Typography className="label-title">
              Description
            </Typography>
            <TextField
              fullWidth
              placeholder="Add a description"
              multiline
              rows={2}
              value={collectionInfo.description}
              onChange={(e) => {setCollectionInfo({...collectionInfo, description: e.target.value})}}
              sx={{mb: 1}}
            />
            <VisibilityRadioInput
              label={
                <Typography className="label-title">
                  Visibility
                </Typography>
              }
              value={collectionInfo.visibility}
              onChange={(value) => {{setCollectionInfo({...collectionInfo, visibility: value})}}}
            />
            {/* <VisibilityInput 
              value={collectionInfo.visibility}
              onChange={(e) => {setCollectionInfo({...collectionInfo, visibility: e.target.value})}}
            /> */}
          </Box>
        </Box>
        <Box sx={{justifyContent: "space-between"}}>
          <Button size="large" id="btn-back"
            variant="contained" onClick={onClose}
          >
            Back
          </Button>

          <Button size="large" id="btn-save"
            variant="contained" onClick={onSubmitCollection}
          >
            {isSubmitting ? <CircularProgress size={24} sx={{color: "#fff", mx: "10px"}} /> : 'Save'}
          </Button>
        </Box>
      </UploadCollectionStyle>
    </>
  )
}

export default UploadCollection;

import { Box, Button, CircularProgress, Input, styled, TextField, Typography, useTheme } from "@mui/material"
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useEffect, useState } from "react";
import { CategoryInput, CollectionInput, VisibilityRadioInput } from "components/input";
import useSnackbar from "components/snackbar/useSnackbar";

const SubmitSoundDetailsStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "&>.MuiBox-root": {
    display: "flex",
  },
  '.MuiTextField-root': {
    "& .MuiOutlinedInput-root": {
      '&:hover:not(.Mui-error) fieldset': {
        borderColor: '#B4B4B4',
      },
      '&.Mui-focused:not(.Mui-error) fieldset': {
        borderColor: '#B4B4B4',
      },
    },
    "& label:not(.Mui-error)": {
      color: '#00000099'
    },
  },
  ".MuiFormControlLabel-root": {
    ".Mui-checked": {
      color: theme.palette.purple.main,
      "&+.MuiTypography-root": {
        color: theme.palette.purple.main
      }
    },
    ".MuiFormControlLabel-label": {
      fontWeight: 500,
      fontSize: "16px",
      color: "#838383"
    },
  },
  ".upload-image": {
    width: "227px",
    height: "246px",
    backgroundColor: "#E3E3E3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundSize: "cover",
    "#btn-upload-image": {
      display: "none"
    }
  },
  ".label-title, #visibility-label": {
    fontSize: "16px",
    fontWeight: 600,
    color: "#858585"
  },
  "#btn-back": {
    backgroundColor: "#858585",
    "&:hover": {
      backgroundColor: "#a6a6a6"
    }
  },
  "#btn-save": {
    backgroundColor: theme.palette.purple.main,
    "&:hover": {
      backgroundColor: theme.palette.purple.light
    },
    '&.Mui-disabled': {
      color: "rgb(185, 185, 185)"
    }
  },
}))

const SubmitSoundDetails = (props) => {
  const { sendAlert } = useSnackbar()

  const {
    basicInfo,
    setBasicInfo,
    handleSwitchStep,
    handleUpload,
    uploadState
  } = props


  const theme = useTheme()

  // handle shange sound title
  const onChangeSoundTitle = (e) => {
    let newSoundTitle = e.target.value
    setBasicInfo({ ...basicInfo, soundTitle: newSoundTitle })
    setValidation({ ...validation, soundTitle: (newSoundTitle ? '' : 'This field is required') })
  }

  // handle validation
  const [validation, setValidation] = useState({
    soundTitle: ''
  })

  // handle validate
  const [validate, setValidate] = useState(true)
  useEffect(() => {
    setValidate(!(basicInfo.soundTitle))
  }, [basicInfo])

  // handle Upload
  const onClickUpload = async () => {
    try {
      await handleUpload()
      handleSwitchStep(1)()
    } catch (e) {
      console.log(e)
      sendAlert('An Unexpected Error Occurred', 'error')
    }
  }

  const addImage = (e) => {
    if (e.target.files[0]) {
      setBasicInfo({
        ...basicInfo, ...{
          cover: e.target.files[0],
          coverSrc: URL.createObjectURL(e.target.files[0])
        }
      })
    }
  }

  return <SubmitSoundDetailsStyle>
    <Box>
      <Box>
        <label htmlFor="btn-upload-image">
          <Box className="upload-image" sx={{
            "&.upload-image": {
              backgroundImage: basicInfo.coverSrc ? `url("${basicInfo.coverSrc}")` : "unset",
            }
          }}>
            <Input accept="image/*" id="btn-upload-image" type="file" onChange={addImage} />
            <Box sx={{ display: basicInfo.cover ? 'none' : 'flex', alignItems: "center", flexDirection: "column" }}>
              <CameraAltIcon sx={{ fontSize: "48px", marginBottom: "10px", color: "#92929D" }} />
              <Typography sx={{ fontWeight: "500", fontSize: "18px", color: "#757575" }}>
                Upload Cover
              </Typography>
            </Box>
          </Box>
        </label>
      </Box>
      <Box sx={{ ml: 2 }}>
        <Typography className="label-title">
          Audio Title
        </Typography>
        <TextField
          error={validation.soundTitle !== ''}
          helperText={validation.soundTitle || ' '}
          fullWidth
          required
          id="sound-title"
          label={basicInfo.soundTitle === '' ? "Add Title" : ''}
          value={basicInfo.soundTitle}
          onChange={onChangeSoundTitle}
          onBlur={onChangeSoundTitle}
          InputLabelProps={{ shrink: false }}
        />
        <Typography className="label-title">
          Description
        </Typography>
        <TextField
          fullWidth
          placeholder="Add a description"
          multiline
          rows={2}
          value={basicInfo.description}
          onChange={(e) => { setBasicInfo({ ...basicInfo, description: e.target.value }) }}
          sx={{ mb: 1 }}
        />
        <Typography className="label-title">
          Collection
        </Typography>
        <CollectionInput
          InputLabelProps={{ shrink: false }}
          sx={{ width: 'unset', mb: 1 }}
          refetchCollections={false}
          label="name"
          value={basicInfo.collection}
          onChange={(event, newValue) => { setBasicInfo({ ...basicInfo, collection: newValue }) }}
        />
        <Typography className="label-title">
          Category
        </Typography>
        <CategoryInput
          curIndex={basicInfo.category}
          setCurIndex={
            (val) => { setBasicInfo({ ...basicInfo, category: val }) }
          }
          sx={{
            'flexWrap': 'wrap',
            '& .active-chip': {
              color: '#fff',
              backgroundColor: 'purple.main'
            },
            '.disabled-chip': {
              color: '#858585'
            },
            '.MuiChip-label': {
              fontSize: 16,
              fontWeight: 500
            },
            '.MuiChip-root': {
              mb: 0.75,
              mr: 0.75,
              ml: 0,
            },
            mb: 1
          }}
        />
        <VisibilityRadioInput
          label={
            <Typography className="label-title">
              Visibility
            </Typography>
          }
          value={basicInfo.visibility}
          onChange={(value) => { { setBasicInfo({ ...basicInfo, visibility: value }) } }}
        />
      </Box>
    </Box>
    <Box sx={{ justifyContent: "space-between" }}>
      <Button size="large" id="btn-back"
        variant="contained" onClick={handleSwitchStep(-1)}
      >
        Back
      </Button>

      <Button size="large" id="btn-save"
        variant="contained" disabled={validate} onClick={onClickUpload}
      >
        {uploadState.isLoading ? <CircularProgress size={24} sx={{ color: "#fff", mx: "10px" }} /> : 'Save'}
      </Button>
    </Box>
  </SubmitSoundDetailsStyle>
}

export default SubmitSoundDetails
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Layout from "../../layouts/BasicLayout";
import { Box, Button, CircularProgress, CssBaseline, Stack, styled, Typography } from "@mui/material";
import logo from 'assets/images/logo.png'
import { TextInput } from "../../components/input/index";
import { Field, reduxForm } from "redux-form";
import Validation from "../../helpers/validation";
import { Link } from "react-router-dom";
import { AuthPageLayout } from "layouts/AuthPage";
import { useForgotPasswordMutation } from "api/userApi";
import useSnackbar from "components/snackbar/useSnackbar";
import useConfirm from "components/confirm/useConfirm";

let ForgotPassword = (props) => {
  const {handleSubmit} = props;
  const [forgotPassword, {isLoading}] = useForgotPasswordMutation()
  const {sendAlert} = useSnackbar()
  const { confirm } = useConfirm()
  const submit = async(form) => {
    try {
      await forgotPassword({
        email: form.email
      }).unwrap()
      await confirm({
        text: "Please recheck your email address",
        title: "Email sent!",
      })
    } catch (e) {
      let errMsg = Object.values(e?.data?.error?.message ?? {})?.[0] ?? "Error"
      sendAlert(errMsg, 'error')
    }
  }

  return (
    <>
      <AuthPageLayout>
        <Layout>
          <Container component="main" maxWidth="sm" className="container-box" >
            <CssBaseline />
            <Box className='logo'>
              <img src={logo} alt='logo'/>
            </Box>
            <Stack spacing={2} className="forgot-pass-desc">
              <Typography>
                Forgot your password?
              </Typography>
              <Typography>
                Please fill in the email that you used to register. We will send you an email that will allow you to reset your password.
              </Typography>
            </Stack>
            <Box className="form-container"
              component="form"
              onSubmit={handleSubmit((form) => submit(form))}
            >
              <Field
                placeholder="Email address"
                name="email"
                component={TextInput}
                validate={[Validation.required, Validation.email]}
              />
              <Button
                type="submit"
                className="submit"
                fullWidth
                variant="contained"
              >
                {
                  isLoading ? 
                  <CircularProgress
                      size={24}
                      sx={{
                          color: "#fff",
                          mr: "10px"
                      }}
                  /> :
                  <Typography>
                    Send Password Reset Email
                  </Typography>
                }
              </Button>
            </Box>
            <Box>
              <Typography className="text-desc">
                Remember your password?&nbsp;
                <Link to="/sign-in">
                  Log In
                </Link>
              </Typography>
            </Box>
          </Container>
        </Layout>
      </AuthPageLayout>
    </>
  );
};


ForgotPassword = reduxForm({
  form: "ForgotPasswordForm",
})(ForgotPassword);

export default ForgotPassword;

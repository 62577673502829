import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import useConfirm from "./useConfirm";
import './index.scss'

const ConfirmDialog = () => {
    const { onConfirm, onCancel, confirmState, closeConfirm } = useConfirm();

    return (
        <>
            <Dialog
                id="dialog-global"
                open={confirmState.show}
                onClose={closeConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {
                    confirmState.title &&
                    (<DialogTitle id="alert-dialog-title">
                        {confirmState.title}
                    </DialogTitle>)
                }
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {confirmState.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="alert-dialog-button" onClick={onCancel}>Cancel</Button>
                    <Button id="alert-dialog-button" onClick={onConfirm} autoFocus>
                        { confirmState.isLoadingCallback ? <CircularProgress size="25px"/> : "OK" }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmDialog;
import { Box, Typography } from "@mui/material"
import TextField from '@mui/material/TextField';
import { TagsInput, VisibilityInput, CollectionInput, CategoryInput } from 'components/input'
import { useEffect, useState } from 'react';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { Alerts } from 'components/alert';
import { CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
    display: 'none',
});

let BasicInfo = (props) => {
    const {
        basicInfo,
        setBasicInfo,
        handleSwitchStep,
        handleUpload,
        uploadState
    } = props

    // handle validate
    const [validate, setValidate] = useState(true)
    useEffect(()=> {
        setValidate(!(basicInfo.soundTitle && basicInfo.tags.length))
    }, [basicInfo])

    // handle upload cover image
    const addCover = (e) => {
        if (e.target.files[0]) {
            setBasicInfo({...basicInfo, ...{
                cover: e.target.files[0],
                coverSrc: URL.createObjectURL(e.target.files[0])
            }})
        }
    }

    // handle Upload
    const onClickUpload = async () => {
        try {
            await handleUpload()
            handleSwitchStep(1)()
        } catch (e) {
            // 
            console.log(e)
        }
    }

    return (
        <>
            <Box
                className="basic-info"
                component="form"
                sx={{ mt: 4, width: "80%", height: "90%" }}
            >
                <div className='upload-sound-title'>
                    <AudiotrackIcon/>
                    <Typography sx={{fontSize:"25px" }}>
                        Fill in some information
                    </Typography>
                </div>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                    <Box className="upload-cover">
                        <CardMedia
                            key={basicInfo.coverSrc}
                            className="cover"
                            component="img"
                            image={basicInfo.coverSrc}
                            alt="song cover"
                            sx={{flexShrink: "0"}}
                        />
                        <label htmlFor="btn-cover">
                            <Input accept="image/*" id="btn-cover" type="file" onChange={addCover} />
                            <Button size="small" variant="outlined" sx={{mt:1}} component="span">
                                Upload cover
                            </Button>
                        </label>
                    </Box>
                    <Box sx={{flex: "1"}}>
                        <TextField
                            fullWidth
                            required
                            value={basicInfo.soundTitle}
                            onChange={(e) => {setBasicInfo({...basicInfo, soundTitle: e.target.value})}}
                            id="sound-title"
                            label="Sound title"
                        />
                        <Typography sx={{fontSize: "14px", mt:2}}>Tags (make it easier to find your sound)</Typography>
                        <TagsInput placeholder="Enter tags here *" tags={basicInfo.tags} setTags={
                            (val) => {setBasicInfo({...basicInfo, tags: val})}}
                        />
                    </Box>
                </Box>
                <Box sx={{display: "flex"}}>
                    <Typography sx={{
                        fontSize: "14px",
                        mt:2,
                        mr: 2
                    }}>Select collection</Typography>
                    <CollectionInput
                        label="name"
                        value={basicInfo.collection}
                        onChange={(event, newValue) => {setBasicInfo({...basicInfo, collection: newValue})}}
                    />
                </Box>
                <VisibilityInput 
                    value={basicInfo.visibility}
                    onChange={(e) => {setBasicInfo({...basicInfo, visibility: e.target.value})}}
                />
                <Typography sx={{fontSize: "14px", mt:2, mr: 2}}>Category</Typography>
                <CategoryInput
                    curIndex={basicInfo.category}
                    setCurIndex={
                        (val) => {setBasicInfo({...basicInfo, category: val})}
                    }
                />
                <Typography sx={{fontSize: "14px", mt:2, mr: 2}}>Description</Typography>
                <TextField
                    fullWidth
                    id="description"
                    label="Description"
                    value={basicInfo.description}
                    onChange={(e) => {setBasicInfo({...basicInfo, description: e.target.value})}}
                />
                <Box className="navigate-box">
                    <Button
                        color="inherit"
                        onClick={handleSwitchStep(-1)}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <LoadingButton loading={uploadState.isLoading} disabled={validate} onClick={onClickUpload}>
                        Upload
                    </LoadingButton>
                </Box>
            </Box>
            {uploadState.error && <Alerts msg="An Unexpected Error Occurred" type="error"></Alerts>}
        </>
    )
}

export default BasicInfo
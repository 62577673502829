import { Field, reduxForm } from "redux-form";
import { AuthPageLayout } from "layouts/AuthPage";
import Layout from "../../layouts/BasicLayout";
import { Box, Button, CircularProgress, Container, CssBaseline, Stack, Typography } from "@mui/material";
import logo from 'assets/images/logo.png'
import { useSearchParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextInput } from "components/input";
import Validation from "../../helpers/validation";
import { useState } from "react";
import useSnackbar from "components/snackbar/useSnackbar";
import useConfirm from "components/confirm/useConfirm";
import { useResetPasswordMutation } from "api/userApi";
import { useNavigate  } from 'react-router-dom';

const validatePasswordsMatch = (value, allValues) =>
    value !== allValues.password ? "Passwords don't match" : undefined;

let ResetPassword = (props) => {
    const { handleSubmit } = props;

    const [params, setParams] = useSearchParams()
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setConfirmPassword] = useState(false);
    const [resetPassword, {isLoading}] = useResetPasswordMutation()
    const {sendAlert} = useSnackbar()
    const { confirm } = useConfirm()
    const navigate = useNavigate()

    let token = params.get('token')
    let email = params.get('email')

    const submit = async (form) => {
        try {
            await resetPassword({
                email: email,
                password: form.password,
                token: token,
                password_confirmation: form.confirm_password
            }).unwrap()
            await confirm({
                text: "You can now login with the new password!",
                title: "Password changed!",
                callback: () => {
                    navigate("/sign-in")
                }
            })
        } catch (e) {
            console.log(e)
            let errMsg = Object.values(e?.data?.error?.message ?? {})?.[0] ?? "Error"
            sendAlert(errMsg, 'error')
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setConfirmPassword(!showConfirmPassword)
    }


    return (<AuthPageLayout>
        <Layout>
            <Container component="main" maxWidth="sm" className="container-box" >
                <CssBaseline />
                <Box className='logo'>
                    <img src={logo} alt='logo' />
                </Box>
                <Stack spacing={2} className="reset-pass-desc">
                    <Typography>
                        Please fill in your new password
                    </Typography>
                </Stack>
                <Box className="form-container"
                    component="form"
                    onSubmit={handleSubmit((form) => submit(form))}
                >
                    <Field
                        endIconEvent={() => handleClickShowPassword()}
                        endIcon={
                            showPassword ? (
                                <Visibility sx={{ color: "#1267FC" }} />
                            ) : (
                                <VisibilityOff sx={{ color: "#1267FC" }} />
                            )
                        }
                        placeholder="Password"
                        fullWidth
                        name="password"
                        type={showPassword ? "text" : "password"}
                        component={TextInput}
                        validate={[Validation.required]}
                    ></Field>
                    <Field
                        // startIcon={<LockOutlinedIcon />}
                        endIconEvent={() => handleClickShowConfirmPassword()}
                        endIcon={
                            showConfirmPassword ? (
                                <Visibility sx={{ color: "#1267FC" }} />
                            ) : (
                                <VisibilityOff sx={{ color: "#1267FC" }} />
                            )
                        }
                        placeholder="Confirm password"
                        fullWidth
                        name="confirm_password"
                        type={showConfirmPassword ? "text" : "password"}
                        component={TextInput}
                        validate={[Validation.required, validatePasswordsMatch]}
                    ></Field>
                    <Button
                        type="submit"
                        className="submit"
                        fullWidth
                        variant="contained"
                    >
                        {
                            isLoading ? 
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "#fff",
                                    mr: "10px"
                                }}
                            /> :
                            <Typography>
                                Reset password
                            </Typography>
                        }
                    </Button>
                </Box>
            </Container>
        </Layout>
    </AuthPageLayout>)
}

ResetPassword = reduxForm({
    form: "resetForm",
})(ResetPassword);

export default ResetPassword;

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import "./style.scss";
import { useLoginSocialMutation, useConnectSocialMutation } from "api/userApi";
import React, { useState } from "react";
import {Alerts} from "components/alert";
import { Button, CircularProgress, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Box } from '@mui/system';

const FacebookButton = (props) => {
    const {textButton, onFailure, customCallback, sx} = props
    const [loginSocial] = useLoginSocialMutation();
    const [alert, setAlert]= useState('');
    const [isFetching, setIsFetching] = useState(false)

    // clean up
    React.useEffect(() => {
        return () => {
            setIsFetching(false)
        }
    }, []);

    

    const callback = async (response) => {
        try {
            if (!response.accessToken || response.error) {
                onFailure()
                return
            }
            if (!customCallback) { // this is default callback
                await loginSocial({
                    access_token: response.accessToken,
                    platform: "facebook"
                });
            } else {
                await customCallback(response)
            }
        } catch (e) {
            //
        } finally {
            setIsFetching(false)
        }
    }

    return (
        <>
            <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                cookie={false}
                xfbml={true}
                autoLoad={false}
                fields="name,email,picture"
                scope="pages_show_list"
                callback={callback}
                onFailure={onFailure}
                render={renderProps => (
                    <Button sx={sx ? sx : { minWidth: "280px", height: "50px"}} onClick={renderProps.onClick} variant="contained" disableElevation>
                        {
                            renderProps.isProcessing || isFetching
                            ? <CircularProgress
                                size={24}
                                sx={{
                                    color: "#fff",
                                    mr: "10px"
                                }}
                            />
                            : <Box display="flex">
                                <FacebookIcon sx={{mr: "15px", fontSize:"35px"}}/>
                                <Typography sx={{m: "auto"}}>{textButton}</Typography>
                            </Box>
                        }
                    </Button>
                )}
                // cssClass="facebook-button-login"
                // textButton={textButton}
            />
            {alert}
        </>
    )
}

export { FacebookButton };
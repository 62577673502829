import React, { useState } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import UploadCollection from "./UploadCollection";
import { useUploadCollectionMutation } from "api/uploadApi";
import './index.scss'

const CreateCollection = () => {
    const [collectionInfo, setCollectionInfo] = useState({
        collectionName: '',
        description: '',
        visibility: 0,
    })

    const [uploadCollection] = useUploadCollectionMutation();

    const submitCollection = async () => {
        // send api to create collection
        return await uploadCollection({
            name: collectionInfo.collectionName,
            description: collectionInfo.description,
            visibility: collectionInfo.visibility
        }).unwrap();
    }

    return (
        <>
            <Box className='create-collection-layout'
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    mt: 4,
                    '& > :not(style)': {
                        m: "auto",
                        width: 600,
                        height: 700,
                    },
                }}
            >
                <Paper elevation={3} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <UploadCollection
                        collectionInfo={collectionInfo}
                        setCollectionInfo={setCollectionInfo}
                        submitCollection={submitCollection}
                    />
                </Paper>
            </Box>
        </>
    );
};

export default CreateCollection;
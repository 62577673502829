import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

const Input = styled('input')({
    display: 'none',
});

const UploadSound = (props) => {
    const {
        handleSwitchStep,
        basicInfo,
        setBasicInfo
    } = props;

    const addFile = (e) => {
        if (e.target.files[0]) {
            setBasicInfo({...basicInfo, ...{
                audio: e.target.files[0],
                audioSrc: URL.createObjectURL(e.target.files[0])
            }})
        }
    }

    const setSoundLength = (e) => {
        setBasicInfo({...basicInfo, soundLength: e.target.duration})
    }

    return (
        <>
            <Box
                className="upload-sound"
                sx={{ mt: 4, width: "80%", height: "90%" }}
            >
                <div className='upload-sound-title'>
                    <TipsAndUpdatesIcon/>
                    <Typography sx={{fontSize:"25px" }}>
                        Create a new sound
                    </Typography>
                </div>
                <Typography sx={{ mt: 2, mb: 5}}>
                    Create sound from a file upload.
                </Typography>
                <label htmlFor="icon-button-file">
                    <Input accept="audio/*" id="icon-button-file" type="file" onChange={addFile} />
                    <IconButton color="primary" disableRipple className='icon-upload' aria-label="upload sound" component="span" sx={{
                        "&:hover": {
                            backgroundColor: "#FFF"
                        },
                    }}>
                        <UploadIcon sx={{
                            fontSize: 80,
                            border: 3,
                            borderRadius: '50%',
                            borderColor: 'grey.500',
                            color: 'grey.500',
                        }} />
                    </IconButton>
                </label>
                {
                    basicInfo.audioSrc &&
                    (<video key={basicInfo.audioSrc} height="240" controls onCanPlayThrough={setSoundLength}>
                        <source src={basicInfo.audioSrc}></source>
                    </video>)
                }
                <Box className="navigate-box">
                    <Button
                        color="inherit"
                        disabled
                        onClick={handleSwitchStep(-1)}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button disabled={!basicInfo.audioSrc} onClick={handleSwitchStep(1)}>
                        Next
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default UploadSound;
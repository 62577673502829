import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Layout from "../../layouts/BasicLayout";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import "./styles.scss"
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Field, reduxForm } from "redux-form";
import Validation from "../../helpers/validation";
import { TextInput } from "../../components/input/index";
import { FacebookButton } from '../../components/social/facebook'
import { useRegisterMutation } from "../../api/userApi";
import {Alerts} from "../../components/alert";
import { Modal } from "@mui/material";
import { Backdrop, CircularProgress, CardActions } from "@mui/material";
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.png'

const theme=createTheme()
const validatePasswordsMatch = (value, allValues) => 
    value !== allValues.password ? "Passwords don't match" : undefined;

let Register = (props) => {
  const [open, setOpen] = React.useState(false);
  // show password for form
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setConfirmPassword(!showConfirmPassword)
  }

  // submit form
  const [register, { error, data, isLoading }] = useRegisterMutation();

  const {handleSubmit, submitting} = props;
  const submit = (form) => {
    register({
      name: form.name,
      email: form.email,
      password: form.password
    })
  }

  
  // handle error message
  const [message, setMessage] = useState('');
  React.useEffect(() => {
    if (error) {
      setMessage('');
      setTimeout(function () {
        if (error.data.errors) {
          setMessage(Object.values(error.data.errors)[0]);
          return;
        }
        setMessage(error.data.message);
      }, 100);
    }
  }, [error]);

  // handle facebook login failure
  // handle facebook login failure
  const onFacebookLoginFail = (res) => {
    setMessage('')
    setTimeout(() => {
      setMessage('Unauthorized!')
    }, 100)
  }

  // handle after register
  React.useEffect(() => {
    if (data?.status === 200) {
      setOpen(true)
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="sign-up-contents">
        <Layout>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm" className="signup-box"
              sx={{
                display: "flex", alignItems:"center", flexDirection:"column"
              }}
            >
              <CssBaseline />
              <Box>
                <img
                  style={{width: "100%"}}
                  src={logo}
                  alt='logo'
                />
              </Box>
              <Box className="sign-up-container">
                <Box>
                  <CardActions>
                    <FacebookButton
                      onFailure={onFacebookLoginFail}
                      textButton="Signup With Facebook"
                      sx={{
                        backgroundColor:"#1F6EEE",
                        ".MuiTypography-root": {
                          fontWeight: 700, fontSize: "14px", fontFamily: "Poppins"
                        }
                      }}
                    />
                  </CardActions>
              </Box>
              <div className="seperation">
                <Typography component="p">
                  or
                </Typography>
              </div>
                <Box
                  component="form"
                  sx={{ mt: 1 }}
                  onSubmit={handleSubmit((form) => submit(form))}
                >
                  <Field
                    placeholder="Name"
                    name="name"
                    component={TextInput}
                    // startIcon={<AccountCircleIcon />}
                    validate={[Validation.required]}
                  ></Field>
                  <Field
                    placeholder="Email"
                    name="email"
                    component={TextInput}
                    // startIcon={<MailOutlinedIcon />}
                    validate={[Validation.required, Validation.email]}
                  ></Field>
                  <Field
                    // startIcon={<LockOutlinedIcon />}
                    endIconEvent={() => handleClickShowPassword()}
                    endIcon={
                      showPassword ? (
                        <Visibility sx={{ color: "#1267FC" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "#1267FC" }} />
                      )
                    }
                    placeholder="Password"
                    fullWidth
                    name="password"
                    type={showPassword ? "text" : "password"}
                    component={TextInput}
                    validate={[Validation.required]}
                  ></Field>
                  <Field
                    // startIcon={<LockOutlinedIcon />}
                    endIconEvent={() => handleClickShowConfirmPassword()}
                    endIcon={
                      showConfirmPassword ? (
                        <Visibility sx={{ color: "#1267FC" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "#1267FC" }} />
                      )
                    }
                    placeholder="Confirm password"
                    fullWidth
                    name="confirm_password"
                    type={showConfirmPassword ? "text" : "password"}
                    component={TextInput}
                    validate={[Validation.required, validatePasswordsMatch]}
                  ></Field>
                  <Button
                    type="submit"
                    className="submit"
                    fullWidth
                    variant="contained"
                    disabled={submitting}
                  >
                    <span>Sign Up</span>
                  </Button>
                  <Box className="remind">
                    <Box>
                      <span>&nbsp;Already have an account? &nbsp;</span>
                    <Link to="/sign-in">
                      Sign in
                    </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Layout>
      </div>
      {message && <Alerts msg={message} type="error" />}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box textAlign='center'
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#fff',
            borderRadius: "10px",
            p: 4}}>
          <Typography
            id="title-success"
            variant="h6"
            component="h2"
            color="#45a044"
            fontFamily="Poppins">
            Thanks! Your account has been successfully created.
          </Typography>
          <Typography id="description-success" sx={{ mt: 2, mb:2, fontFamily: "Poppins" }}>
            Click  <Link to="/sign-in">here</Link> to login
          </Typography>
          <Button onClick={handleClose} variant="contained">Close</Button>
        </Box>
      </Modal>
    </>
  );
};

Register = reduxForm({
  form: "registerForm",
})(Register);

export default Register;

import Home from "../pages/Home";
import MyCollection from '../pages/my-collection'
import CreateSound from "pages/create-sound";
import CreateCollection from "pages/create-collection";
import Library from "../pages/library";
import CategorySounds from "../pages/library/category-sounds";
import MyCollectionDetails from "pages/my-collection-details";
import BlockList from "pages/block-list";
import UserInfo from "pages/user-info";
import StreamHistory from "pages/stream-history";

const routes = [
    {
        path: '/',
        name: 'Home',
        auth: true,
        component: <Home />,
      },
      {
        path: '/My-Collections',
        name: 'My Collections',
        auth: true,
        component: <MyCollection />,
      },
      {
        path: '/My-Collections/:id',
        name: 'My Collection Details',
        auth: true,
        component: <MyCollectionDetails />,
      },
      {
        path: '/Create',
        name: 'Create',
        component: <div>Create</div>,
      },
      {
        path: '/Create/New-Sound',
        name: 'Create new sound',
        component: <CreateSound />,
      },
      {
        path: '/Create/New-Collection',
        name: 'Create new collection',
        component: <CreateCollection />,
      },
      {
        path: '/Library',
        name: 'Library',
        component: <Library />,
      },
      {
        path: '/Library/Sounds/:id',
        name: 'Library-Sounds',
        component: <CategorySounds />,
      },
      {
        path: '/StreamHistory',
        name: 'StreamHistory',
        component: <StreamHistory />
      },
      {
        path: '/Blocklist',
        name: 'Blocklist',
        component: <BlockList />
      },
      {
        path: '/Userinfo',
        name: 'BlockInfo',
        component: <UserInfo />
      }
];

export default routes;
const { styled, Box, Typography, Button, useTheme } = require("@mui/material")

const SubmitSoundFinishStyle = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column'
}))

const TextStyle = styled(Typography)(({theme}) => ({
  fontSize: 20,
  marginBottom: 32,
  textAlign: 'center'
}))

const SubmitSoundFinish = (props) => {
  const {setOpen} = props
  const theme = useTheme()
  return (
    <SubmitSoundFinishStyle>
      <TextStyle sx={{ color: theme.palette.purple.main, fontSize: 25 }}>
        Successfully uploaded!
      </TextStyle>
      <TextStyle sx={{mb: 9}}>
        You Can Find Your New Sound In Homepage or Collection
      </TextStyle>
      <Box sx={{ display: 'flex', justifyContent: 'center'}}>
        <Button
          size="large" sx={{
            backgroundColor: theme.palette.purple.main,
            '&:hover' : {
              backgroundColor: theme.palette.purple.light
            }
          }}
          variant="contained" onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </Box>
    </SubmitSoundFinishStyle>
  )
}

export default SubmitSoundFinish
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    open: false,
    msg: '',
    type: 'info',
}

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        sendAlert: (state, action) => {
            state.msg = action.payload?.msg ?? '';
            state.type = action.payload?.type ?? 'info';
            state.open = true;
        },
        close: (state) => {
            state.open = false;
        }
    }
})
import React, { useState } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import UploadSound from "./UploadSound";
import BasicInfo from "./BasicInfo";
import FinishUpload from "./FinishUpload";
import './index.scss'
import { useUploadSoundMutation } from "api/uploadApi";
import vinyl from 'assets/images/vinyl.png'

const CreateSound = () => {
    const [uploadSound, uploadState] = useUploadSoundMutation();
    const [activeStep, setActiveStep] = React.useState(0);
    const [basicInfo, setBasicInfo] = useState({
        audio: null,
        audioSrc: '',
        cover: null,
        coverSrc: vinyl,
        soundTitle: '',
        visibility: 0,
        category: null,
        tags: [],
        description: '',
        soundLength: 0,
        collection: null,
    })
    
    const handleSwitchStep = (step) => {
        return () => {setActiveStep((prevActiveStep) => prevActiveStep + step)}
    }

    const handleUpload = async () => {
        const soundPayload = new FormData()
        soundPayload.append('sound', basicInfo.audio)
        soundPayload.append('sound_title', basicInfo.soundTitle)
        soundPayload.append('sound_length', basicInfo.soundLength)
        soundPayload.append('visibility', basicInfo.visibility)
        soundPayload.append('category', basicInfo.category)
        soundPayload.append('tags', basicInfo.tags.join(', '))
        soundPayload.append('description', basicInfo.description)
        soundPayload.append('collection', basicInfo.collection ? basicInfo.collection.id : -1)
        if (basicInfo.cover) {
            soundPayload.append('cover', basicInfo.cover)
        }
        return await uploadSound(soundPayload).unwrap()
    }

    const steps = [
        {
            name: 'Upload sound',
            component: <UploadSound
                handleSwitchStep={handleSwitchStep}
                basicInfo={basicInfo}
                setBasicInfo={setBasicInfo}
            />
        },
        {
            name: 'Basic info',
            component: <BasicInfo
                basicInfo={basicInfo}
                setBasicInfo={setBasicInfo}
                handleSwitchStep={handleSwitchStep}
                handleUpload={handleUpload}
                uploadState={uploadState}
            />
        },
        {
            name: 'Finish',
            component: <FinishUpload />
        }
    ]

    return (
        <Box sx={{ width: '100%', mt: 4 }}>
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                    const stepProps = {};
                    return (
                        <Step key={step.name} {...stepProps} sx={{
                            '& .MuiSvgIcon-root': {
                                color: "#ffffff69"
                            }
                        }}>
                            <StepLabel>
                                <Typography sx={{ color:"#fff" }}>{step.name}</Typography>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <>
                <Typography sx={{ mt: 2, mb: 1, color:"#fff" }}>Step {activeStep + 1}</Typography>
                <Box className='create-sound-layout'
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: "auto",
                            width: 600,
                            height: 700,
                        },
                    }}
                >
                    <Paper elevation={3} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        {steps[activeStep].component}
                    </Paper>
                </Box>
                {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button onClick={handleNext} disabled={disableNext}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box> */}
            </>
        </Box>
    );
};

export default CreateSound;
import { Box, Typography } from "@mui/material"
import Button from '@mui/material/Button'

let FinishUpload = () => {
    return (
        <>
            <Box
                className="finish-upload"
                sx={{ mt: 4, width: "80%", height: "90%" }}
            >
                <Typography sx={{fontSize: "25px", mt:4, textAlign: "center", color:"#4caf50"}}>
                    Successfully uploaded!
                </Typography>
                <Typography sx={{fontSize: "25px", mt:4, textAlign: "center"}}>
                    Find Your New Sound In Homepage or Profile!
                </Typography>
                <Button className="btn-home-page" variant="contained" sx={{mt: 4}}>
                    <a href="/">
                        Go to homepage
                    </a>
                </Button>
                <Button className="btn-new-sound" variant="outlined" sx={{mt: 4}}>
                    <a href="/Create/New-Sound">
                        Create new sound
                    </a>
                </Button>
            </Box>
        </>
    )
}

export default FinishUpload
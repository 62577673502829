import { Box, styled } from "@mui/material";

export const AuthPageLayout = styled(Box)(({ theme }) => ({
    ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
        width: "90vw",
        maxWidth: "600px",
        height: "80vh",
        backgroundColor: "#000",
        boxShadow: "0px 0px 30px 10px #ffffff73",
        margin: "0 auto",
        ".MuiTypography-root": {
            color: "#D0D0DB"
        },
        ".container-box": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "0 5vw",
            '.logo': {
                img: {
                    width: "100%"
                },
                paddingBottom: "60px",
            },
            ".forgot-pass-desc": {
                textAlign: "center",
                ".MuiTypography-root": {
                    fontWeight: "500",
                    fontSize: "14px",
                    "&:first-child": {
                        fontWeight: "700",
                        fontSize: "18px"
                    }
                }
            },
            ".form-container": {
                marginTop: "20px",
                ".submit": {
                    margin: "10px auto 50px auto",
                    backgroundColor: "#5D5FEF",
                    borderRadius: "10px",
                    height: "42px",
                    ".MuiTypography-root": {
                        fontSize: "14px",
                        fontWeight: "700"
                    },
                    "&.MuiButton-root:hover": {
                        backgroundColor: "#4b4dbb"
                    }
                }
            }
        },
        [theme.breakpoints.up('md')]: {
            width: "45vw"
        },
        ".text-desc": {
            fontSize: "14px",
            fontWeight: 700,
            a: {
                textDecoration: "none",
                color: "#5D5FEF"
            }
        }
    }
}))